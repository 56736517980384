import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  MdOutlineCancel,
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlinePrint,
  MdPrint,
} from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { formatTimestamp } from "../../components/Shared/SharedMethods";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyInvoicesList,
  getInvoicesList,
  printInvoice,
} from "../../store/slices/InvoicesSlice";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../components/Shared/CustomPagination";
import { useTranslation } from "react-i18next";
import { formatDate } from "../Transactions/TransactionsTable";
import { TfiPrinter } from "react-icons/tfi";
import { HiOutlinePrinter } from "react-icons/hi2";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import ReceivePaymentInvoice from "./ReceivePaymentInvoice";

export default function InvoiceTable({ invoicesList }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [receivePaymentDialogVisible, setReceivePaymentDialogVisible] =
    useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const datebody = (rowData) => {
    return formatTimestamp(rowData.date);
  };
  const handlePrintInvoice = async (id) => {
    try {
      const resultAction = await dispatch(printInvoice(id));
      if (printInvoice.fulfilled.match(resultAction)) {
        navigate("/manager/show-invoice");
      } else if (printInvoice.rejected.match(resultAction)) {
        console.error("Failed to print invoice:", resultAction.error);
      }
    } catch (error) {
      console.log("error", error);
    }

    // print the invoice using Redux dispatch and html2pdf library
    // try {
    //   const result = await dispatch(printInvoice(id)).unwrap();
    //   const element = document.createElement("div");
    //   element.innerHTML = result;
    //   html2pdf().from(element).save(`invoice_${id}.pdf`);
    // } catch (error) {
    //   console.error("Failed to print invoice:", error);
    // }
  };
  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(getInvoicesList({ page: newPage, per_page: newRowsPerPage }));
  };
  const { t } = useTranslation();

  const templateBody = (rowData) => (
    <div className="flex gap-1">
      <SharedTooltip content="print" id="print" />
      <SharedTooltip content="payment" id="payment" />
      {/* <SharedTooltip id="edit" content="edit" />
      <SharedTooltip id="delete" content="delete" />
      <SharedTooltip id="print" content="print" /> */}

      <HiOutlinePrinter
        size={25}
        data-tooltip-id="print"
        className="!p-1 !text-black cursor-pointer"
        onClick={() => {
          handlePrintInvoice(rowData?.id);
        }}
      />
      {rowData.paid !== true && (
        <LiaFileInvoiceDollarSolid
          size={25}
          data-tooltip-id="payment"
          className="!p-1 !text-black hover:cursor-pointer"
          onClick={() => {
            setSelectedEntry(rowData);
            setReceivePaymentDialogVisible(true);
          }}
        />
      )}
      <MdOutlineCancel
        size={25}
        data-tooltip-id="cancel"
        className="!p-1 !text-black cursor-pointer"
        onClick={() => {
          if (rowData?.invoice_type === "credit") {
            navigate("/manager/insuranceInvoice/create", {
              state: { data: rowData, cancel: true },
            });
          } else {
            navigate("/manager/invoices/create", {
              state: { data: rowData, cancel: true },
            });
          }
        }}
      />
      {/* <MdOutlineEdit
        size={25}
        data-tooltip-id="edit"
        className="!p-1 !text-black cursor-pointer"
      />
      <MdOutlineDelete
        size={25}
        data-tooltip-id="delete"
        className="!p-1 !text-black cursor-pointer"
      />
      <MdOutlinePrint
        size={25}
        data-tooltip-id="print"
        className="!p-1 !text-black cursor-pointer"
        onClick={() => handlePrintInvoice(rowData?.id)}
      /> */}
    </div>
  );
  const statusBody = (data) => {
    if (data?.paid) {
      return (
        <div className="bg-green-500 !w-[50px] p-1 text-center text-white rounded-md">
          {t("Paid")}
        </div>
      );
    } else {
      return (
        <div className="bg-red-500 p-1 !w-[50px] text-center text-white rounded-md">
          {t("Unpaid")}
        </div>
      );
    }
  };
  const sequenceBodyTemplate = (rowData, options) => {
    return (
      options.rowIndex + 1 + (invoicesList?.page - 1) * invoicesList?.per_page
    );
  };

  const balanceBody = (data) => {
    return (
      <>
        {data?.patient_share} {t("SAR")}
      </>
    );
  };
  const VatBody = (data) => {
    return (
      <>
        {data?.patient_tax} {t("SAR")}
      </>
    );
  };

  return (
    <>
      <DataTable value={invoicesList?.data} tableStyle={{ minWidth: "50rem" }}>
        <Column
          body={sequenceBodyTemplate}
          header={t("no")}
          style={{ width: "3rem" }}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="date"
          header={t("date")}
          body={(rowData) => formatDate(rowData.date)}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="invoice_number"
          header={t("invoiceNumber")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="patient.name"
          header={`${t("patient")} ${t("name")}`}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="patient_share"
          header={t("patientShare")}
          body={balanceBody}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          field="patient_tax"
          header={t("totalVAT")}
          body={VatBody}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        {/* <Column
          field="total_vat"
          header={t("totalVAT")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary ",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        /> */}

        <Column
          field="file_number"
          header={t("fileNumber")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary ",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="nationality"
          header={t("nationality")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          body={statusBody}
          field="paid"
          header={t("status")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="description"
          header={t("description")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        {/* <Column
          field="date"
          header={t("date")}
          // body={datebody}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        /> */}

        <Column
          body={templateBody}
          header={t("actions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
      </DataTable>
      {invoicesList?.data?.length > 0 && (
        <CustomPagination
          currentPage={invoicesList?.page}
          rowsPerPage={invoicesList?.per_page}
          totalRecords={invoicesList?.total_pages}
          totalRecord={invoicesList?.total_record}
          onPageChange={onPageChange}
        />
      )}
      {/* {console.log("YEhh: ", selectedEntry)} */}
      <ReceivePaymentInvoice
        open={receivePaymentDialogVisible}
        setOpen={setReceivePaymentDialogVisible}
        selectedEntries={selectedEntry ? [selectedEntry] : []}
      />
    </>
  );
}
