import React, { useEffect, useState } from "react";
import SharedButton from "../../components/Shared/SharedButton";
import CreateItem from "./CreateItem";
import ItemsTable from "./ItemsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getItems,
  getItemsList,
  getSearchedItem,
} from "../../store/slices/ItemSlice";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

export default function ItemsMain() {
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getItems());
    dispatch(getItemsList({ page: 1, per_page: 10 }));
  }, [dispatch]);

  const { itemsList, items, isLoading } = useSelector((state) => state.item);
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const [hasCalledList, setHasCalledList] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState(null);

  // const handleSearch = async () => {
  //   if (searchValue.trim().length >= 8) {
  //     const data = await dispatch(
  //       getSearchedItem({ query: searchValue.trim() })
  //     );

  //     // Update the invoices list with the search results
  //     if (data?.data && data?.data.length > 0) {
  //       setFilteredInvoices(data);
  //     }
  //   } else {
  //     setFilteredInvoices(null);
  //     dispatch(getItemsList({ page: 1, per_page: 10 }));
  //   }
  // };

  const handleSearch = async () => {
    if (searchValue.trim().length >= 8) {
      const data = await dispatch(
        getSearchedItem({ search: searchValue.trim(), page: 1, per_page: 10 })
      );

      if (data?.data && data?.data.length > 0) {
        setFilteredInvoices(data);
      }
    } else {
      setFilteredInvoices(null);
      dispatch(getItemsList({ page: 1, per_page: 10 }));
    }
  };

  return (
    <div>
      <div className="flex justify-between mb-3">
        <SharedTooltip
          content={t("Keep_track_of_your_items")}
          position="left"
          id="Items"
        />
        <span
          data-tooltip-id="Items"
          className="font-semibold text-black/70 text-xl mt-2 "
        >
          {t("ITEMS")}
          <span className="italic text-lg ml-1">
            ({itemsList?.total_record || 0}
            <span className="text-xs font-bold italic"> {t("Entries")}</span>)
          </span>
        </span>

        <div className="flex items-center gap-4">
          {/* <div className="flex gap-2">
            <input
              placeholder={t("Search by Name or Code")}
              type="text"
              className="border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 hover:border-primary !outline-none !shadow-none"
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (e.target.value.length < 8 && !hasCalledList) {
                  dispatch(getItemsList({ page: 1, per_page: 10 }));
                  setHasCalledList(true);
                } else if (e.target.value.length >= 8) {
                  setHasCalledList(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <span
              className="!bg-primary !text-white rounded-md p-2.5"
              onClick={() => handleSearch()}
            >
              <FaSearch />
            </span>
          </div> */}
          <div className="flex gap-2">
            <input
              placeholder={t("Search by Name or Code")}
              type="text"
              className="border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 hover:border-primary !outline-none !shadow-none"
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (e.target.value.length < 8 && !hasCalledList) {
                  dispatch(getItemsList({ page: 1, per_page: 10 }));
                  setHasCalledList(true);
                } else if (e.target.value.length >= 8) {
                  setHasCalledList(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <span
              className="!bg-primary !text-white rounded-md p-2.5"
              onClick={() => handleSearch()}
            >
              <FaSearch />
            </span>
          </div>

          <SharedButton
            label={t("CreateItem")}
            icon="pi pi-plus"
            onClick={() => setCreateDialogVisible(true)}
            className="bg-gradient"
          />
        </div>
      </div>

      <CreateItem
        createDialogVisible={createDialogVisible}
        setCreateDialogVisible={setCreateDialogVisible}
        isLoading={isLoading}
        editData={null}
      />
      {/* <ItemsTable data={items} /> */}
      <ItemsTable
        data={filteredInvoices?.length ? filteredInvoices : itemsList || []}
      />
      <Loader showLoader={isLoading} />
    </div>
  );
}
