import React, { useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import Loader from "../../Loader";
import CustomPagination from "../../components/Shared/CustomPagination";
import {
  deleteCenter,
  getCostCenterList,
} from "../../store/slices/CostCenterSlice";
import CreateCostCenter from "./CreateCostCenter";

export default function CostCenterTable({ data }) {
  const { isLoading } = useSelector((state) => state.costCenter);
  const dispatch = useDispatch();
  const lang = i18next.language;
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(getCostCenterList({ page: newPage, per_page: newRowsPerPage }));
  };

  const { t } = useTranslation();

  const templateBody = (rowData) => {
    return (
      <div className="flex  gap-1">
        <SharedTooltip content="view" id="my-tooltip-1" />
        <SharedTooltip id="my-tooltip-2" content="edit" />
        <SharedTooltip id="my-tooltip-3" content="delete" />
        {/* <IoMdEye
          size={25}
          data-tooltip-id="my-tooltip-1"
          className="!p-1 !text-black hover:cursor-pointer"
        /> */}
        <MdOutlineEdit
          size={25}
          data-tooltip-id="my-tooltip-2"
          className="!p-1 !text-black hover:cursor-pointer"
          onClick={() => {
            setEditData(rowData);
            setCreateDialogVisible(true);
          }}
        />
        <MdOutlineDelete
          size={25}
          data-tooltip-id="my-tooltip-3"
          className="!p-1 !text-black hover:cursor-pointer"
          onClick={() => {
            dispatch(deleteCenter({ id: rowData?.id })).then(() => {
              dispatch(getCostCenterList({ page: 1, per_page: 10 }));
            });
          }}
        />
      </div>
    );
  };

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };

  return (
    <>
      <DataTable value={data?.data} tableStyle={{ minWidth: "50rem" }}>
        <Column
          body={sequenceBodyTemplate}
          header={t("no")}
          style={{ width: "3rem" }}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          field={lang === "ar" ? "name_ar" : "name_en"}
          header={t("name")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="description"
          header={t("description")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          body={templateBody}
          header={t("actions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
      </DataTable>
      <CreateCostCenter
        setCreateDialogVisible={setCreateDialogVisible}
        createDialogVisible={createDialogVisible}
        dispatch={dispatch}
        editData={editData}
      />
      <Loader showLoader={isLoading} />
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
