import { Formik, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrentDateTime } from "../Transactions/CreateTransaction";
import SharedInputText from "../../components/Shared/SharedInputText";
import { useDispatch, useSelector } from "react-redux";
import { getCostCenter } from "../../store/slices/CostCenterSlice";
import CustomSearchInput from "../../components/Shared/CustomSearchInput";
import i18next from "i18next";
import SharedButton from "../../components/Shared/SharedButton";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  CreatePaymentVoucherApi,
  getBankAccounts,
  getCashAccounts,
  updatePaymentVoucher,
  updateReceiptVoucher,
} from "../../store/slices/VoucherSlice";
import * as Yup from "yup";
import Loader from "../../Loader";
import { FaArrowLeft } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  voucher_type: Yup.string().required("Voucher type is required"),
  date: Yup.string().required("Date is required"),
  total_amount: Yup.number()
    .required("Total amount is required")
    .test(
      "match-total",
      "Total amount must be equal to the sum of details amounts",
      function (value) {
        const { details } = this.parent;
        const sumDetailsAmount = details.reduce(
          (sum, detail) => sum + detail.amount,
          0
        );
        return value === sumDetailsAmount;
      }
    ),
  cost_center_id: Yup.number().nullable().required("Cost center is required"),
  details: Yup.array().of(
    Yup.object().shape({
      account_id: Yup.number().nullable().required("Account is required"),
      amount: Yup.number()
        .required("Amount is required")
        .min(1, "Amount must be greater than 0"),
      description: Yup.string().nullable(),
    })
  ),
});

function CreatePaymentVoucher() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { costCenters } = useSelector((state) => state.costCenter);
  const { Accounts, isLoading, paymentVouchers } = useSelector(
    (state) => state.voucher
  );
  const location = useLocation();
  const editData = location?.state?.data;
  const lang = i18next.language;
  const [vType, setVType] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCashAccounts());
  }, [dispatch]);

  const initialValues = {
    voucher_type: editData ? editData?.voucher_type : "CPV",
    date: editData ? editData?.date : getCurrentDateTime(),
    total_amount: editData ? editData?.total_amount : 0,
    cost_center_id: editData ? editData?.cost_center_id : null,
    created_by: editData ? editData?.created_by : 34,
    details: editData
      ? editData?.details.map((detail) => ({
          account_id: detail?.account_id,
          amount: detail?.amount,
          description: detail?.description,
        }))
      : [
          {
            account_id: null,
            amount: 0,
            description: "",
          },
        ],
  };

  const listCostCentervalues = costCenters?.data
    ? Object?.entries(costCenters?.data || [])?.map(([id, costCenter]) => ({
        value: Number(costCenter?.id),
        label: lang === "ar" ? costCenter?.name_ar : costCenter?.name_en,
      }))
    : [];
  const listAcountvalues = costCenters?.data
    ? Object?.entries(Accounts?.data || [])?.map(([id, acount]) => ({
        value: Number(acount?.code),
        label: lang === "ar" ? acount?.name_ar : acount?.name_en,
      }))
    : [];

  useEffect(() => {
    if (vType === "CPV") {
      dispatch(getCashAccounts());
    } else if (vType === "BPV") {
      dispatch(getBankAccounts());
    }
  }, [vType]);
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        if (editData) {
          dispatch(
            updatePaymentVoucher({ id: editData?.id, data: values })
          ).then(() => {
            navigate("/manager/payment/voucher");
          });
        } else {
          dispatch(CreatePaymentVoucherApi(values)).then(() => {
            navigate("/manager/payment/voucher");
          });
        }
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <div className="rounded-md bg-gray-100 p-6 w-full gap-4">
            <div className="flex gap-3 items-center">
              <p
                className=" !h-50 p-2 cursor-pointer !w-50 mb-5 rounded-full !bg-primary !text-white"
                onClick={() => {
                  navigate("/manager/payment/voucher");
                }}
              >
                <FaArrowLeft size={17} />
              </p>
              <div className=" font-semibold mb-5 text-[20px]">
                {t("Create Payment Voucher")}
              </div>
            </div>

            <div className="grid grid-cols-4 gap-4 mb-5">
              <div className="flex flex-col gap-1 w-full">
                <label className="text-base font-medium">
                  {t("Voucher Type")}
                </label>
                <div className="flex w-full">
                  <button
                    type="button"
                    onClick={() => {
                      props.setFieldValue("voucher_type", "CPV");
                      setVType("CPV");
                    }}
                    className={`w-[50%] border !p-1.5 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                      props.values.voucher_type === "CPV"
                        ? "!bg-primary !border-primary text-white"
                        : "!bg-white !border-white text-primary"
                    }`}
                  >
                    CPV
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      props.setFieldValue("voucher_type", "BPV");
                      setVType("BPV");
                    }}
                    className={`w-[50%] border !p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                      props.values.voucher_type === "BPV"
                        ? "!bg-primary !border-primary text-white"
                        : "!bg-white !border-white text-primary"
                    }`}
                  >
                    BPV
                  </button>
                </div>
              </div>

              <SharedInputText
                type="datetime-local"
                label={t("date")}
                name="date"
                placeholder="Enter Date"
                className="!p-1.5"
              />
              <SharedInputText
                type="number"
                label={t("total_amount")}
                name="total_amount"
                placeholder="Enter Amount"
                className="!p-1.5"
              />

              {/* Cost Center Search */}
              <CustomSearchInput
                label={t("costCenter")}
                className="!w-full"
                name="cost_center_id"
                options={listCostCentervalues}
                placeholder={`${t("select")} ${t("costCenter")}`}
              />
            </div>

            <div>
              <label className="text-[18px] font-medium">{t("Details")}</label>
              <FieldArray
                name="details"
                render={(arrayHelpers) => (
                  <div className="!mt-3">
                    {props?.values?.details?.map((detail, index) => (
                      <div className="grid grid-cols-5 gap-4 mb-4" key={index}>
                        <CustomSearchInput
                          label={t("Account")}
                          className="!w-full"
                          name={`details.${index}.account_id`}
                          options={listAcountvalues}
                          placeholder={`${t("select")} ${t("account")}`}
                        />
                        <SharedInputText
                          type="number"
                          label={t("amount")}
                          name={`details.${index}.amount`}
                          placeholder="Enter amount"
                          className="!p-1.5"
                        />
                        <SharedInputText
                          type="text"
                          label={t("description")}
                          name={`details.${index}.description`}
                          placeholder="Enter description"
                          className="!p-1.5"
                          onKeyDown={(e) => {
                            if (
                              e.key === "Tab" &&
                              !e.shiftKey &&
                              index === props?.values?.details.length - 1
                            ) {
                              e.preventDefault();
                              arrayHelpers.push({
                                account_id: null,
                                amount: 0,
                                description: "",
                              });
                            }
                          }}
                        />

                        {index > 0 && (
                          <div className="flex  items-end ">
                            <span className="h-[30px] w-full  ">
                              <RiDeleteBin5Line
                                size={20}
                                className="cursor-pointer text-red-500"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                    <SharedButton
                      icon="pi pi-plus"
                      label={t("Add Detail")}
                      className="!p-1.5 !min-w-14 bg-gradient"
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          account_id: null,
                          amount: 0,
                          description: "",
                        })
                      }
                    />
                  </div>
                )}
              />
            </div>

            <div className="mt-4 flex justify-center">
              <SharedButton
                icon="pi pi-check"
                label={editData ? t("Update") : t("Submit")}
                className="!p-1.5 !min-w-14 bg-gradient"
                type="submit"
              />
            </div>
          </div>
          <Loader showLoader={isLoading} />
        </form>
      )}
    </Formik>
  );
}

export default CreatePaymentVoucher;
