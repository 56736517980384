import React, { useEffect } from "react";
import { Field, Formik } from "formik";
import * as yup from "yup";
import SharedInputText from "../../components/Shared/SharedInputText";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import SharedDropdown from "../../components/Shared/SharedDropdown";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import { getItems, getPriceListByID } from "../../store/slices/ItemSlice";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CreateNewPurchasedEntry,
  getPurchasedEntryList,
  getVendors,
} from "../../store/slices/PurchaseSlice";
import i18next from "i18next";
import Loader from "../../Loader";
import { getExpenseAccounts } from "../../store/slices/AcountsSlice";
import { getBranches } from "../../store/slices/BusinessSlice";
import CustomSearchInput from "../../components/Shared/CustomSearchInput";

export default function CreatePurchasedEntryPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = i18next.language;

  useEffect(() => {
    dispatch(getPriceListByID({ payer_id: "0" }));
    dispatch(getVendors());
    dispatch(getExpenseAccounts());
  }, []);

  const { expenseAccounts } = useSelector((state) => state.acount);
  const { isLoading } = useSelector((state) => state.purchase);
  const { items } = useSelector((state) => state.item);
  const { vendors } = useSelector((state) => state.purchase);
  const { user } = useSelector((state) => state.auth);

  const listAccountsvalues = expenseAccounts?.data
    ? Object.entries(expenseAccounts.data).map(([id, account]) => ({
        value: Number(account?.code),
        label: lang === "ar" ? account.name_ar : account.name_en,
      }))
    : [];
  function convertData(inputData) {
    return Object.values(inputData).map((item) => ({
      value: item.ID,
      label: lang === "ar" ? item?.name_ar : item?.name_en,
      price: item.price,
      name_en: item?.name_en,
      name_ar: item?.name_ar,
    }));
  }
  const ItemsOptions = convertData(items.data || []);

  function vendor(inputData) {
    return Object.values(inputData).map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
  }

  const taxCheckOptions = [
    { value: true, label_en: "Yes", label_ar: "نعم" },
    { value: false, label_en: "No", label_ar: "لا" },
  ];
  const getTaxCheckOptions = (lang) => {
    return taxCheckOptions.map((option) => ({
      value: option.value,
      label: lang === "ar" ? option.label_ar : option.label_en,
    }));
  };

  const unitTypeOptions = [
    { value: "Service", label_en: "Service", label_ar: "خدمة" },
    { value: "Kilogram", label_en: "Kilogram", label_ar: "كيلوجرام" },
    { value: "Gram", label_en: "Gram", label_ar: "جرام" },
    { value: "Milligram", label_en: "Milligram", label_ar: "ميليجرام" },
  ];
  const getUnitTypeOptions = (lang) => {
    return unitTypeOptions.map((option) => ({
      value: option.value,
      label: lang === "ar" ? option.label_ar : option.label_en,
    }));
  };

  const getLabel = (item) => {
    const object = ItemsOptions.find((itm) => Number(itm.id) === item.item_id);
    return object ? object.name : "";
  };
  const getAmount = (item) => {
    const object = ItemsOptions.find((itm) => Number(itm.id) === item.item_id);
    return object ? object.price : "";
  };

  const initialValues = {
    vendor_id: "",
    purchase_date: "",
    notes: "",
    refrence: "",
    items: [],
  };

  const validationSchema = yup.object().shape({
    vendor_id: yup.number().required(t("required")),
    purchase_date: yup.string().required(t("required")),
    notes: yup.string().required(t("required")),
    refrence: yup.string().required(t("required")),
    items: yup
      .array()
      .of(
        yup.object().shape({
          // item_id: yup.number().required(t("required")),
          // name: yup.string().required(t("required")),

          // unit: yup.string().required(t("required")),

          // quantity: yup
          //   .number()
          //   .integer()
          //   .min(1, "Quantity must be at least 1")
          //   .required(t("required")),
          price: yup.number().required(t("required")),
          // account_code: yup.number().required(t("required")),
          discount_amount: yup.number().required(t("required")),
          tax_check: yup.bool().required(t("required")),
        })
      )
      .min(1, t("required")),
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { resetForm }) => {
          const resultAction = await dispatch(CreateNewPurchasedEntry(data));
          if (CreateNewPurchasedEntry.fulfilled.match(resultAction)) {
            navigate("/manager/purchased_entry");
            resetForm();
            dispatch(getPurchasedEntryList({ page: 1, per_page: 10 }));
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validate={(values) => {
          validationSchema
            .validate(values, { abortEarly: false })
            .then(() => console.log("Validation passed"))
            .catch((err) => console.log("Validation errors:", err.errors));
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="rounded-md bg-gray-100 p-6 w-full gap-4">
              <h2 className="text-3xl font-bold mb-6 text-gray-800">
                {`${t("CreatePurchasedEntry")}`}
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
                <CustomSearchInput
                  name="vendor_id"
                  placeholder={`${t("select")} ${t("vendor")}`}
                  options={vendor(vendors || [])}
                  label={t("vendors")}
                  className={"!w-full"}
                />
                <SharedInputText
                  type={"date"}
                  label={t("purchase_date")}
                  name={"purchase_date"}
                  placeholder={`${"enter purchase_date"}`}
                  className="!p-1.5"
                />

                <SharedInputText
                  label={t("refrence")}
                  name={"refrence"}
                  placeholder={`${t("enter")} ${t("refrence")}`}
                  className="!p-1.5"
                />

                <SharedInputText
                  label={t("description")}
                  name={"notes"}
                  placeholder={`${t("enter")} ${t("description")}`}
                  className="!p-1.5"
                />
              </div>

              <div className="mt-4">
                {props.values.items.map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-8 gap-1 mb-4 border-t py-4"
                  >
                    {/* <div className="grid grid-cols-4 gap-4">
                     */}

                    <Field
                      name={`items[${index}].name`}
                      as={SharedInputText}
                      label={`${t("item")} ${t("name")}`}
                      type="text"
                      placeholder={`${t("enter")} ${t("name")}`}
                      className="!p-1.5"
                      value={item.name_en}
                      onChange={(e) => {
                        const newItems = [...props.values.items];
                        newItems[index].name_en = e.target.value;
                        props.setFieldValue("items", newItems);
                      }}
                    />
                    <Field
                      name={`items[${index}].name`}
                      as={SharedInputText}
                      label={`${t("item")} ${t("name")}`}
                      type="text"
                      placeholder={`${t("enter")} ${t("name")}`}
                      className="!p-1.5"
                      value={item.name_ar}
                      onChange={(e) => {
                        const newItems = [...props.values.items];
                        newItems[index].name_ar = e.target.value;
                        props.setFieldValue("items", newItems);
                      }}
                    />
                    <CustomSearchInput
                      label={`${t("expense")} ${t("account")}`}
                      className="!w-full"
                      name={`items[${index}].account_code`}
                      options={listAccountsvalues}
                      placeholder={`${t("select")} ${t("account")}`}
                    />
                    {/* <Field
                        name={`items[${index}].name`}
                        as={SharedInputText}
                        label={`${t("item")} ${t("name")}`}
                        type="text"
                        placeholder={`${t("enter")} ${t("name")}`}
                        className="!p-1.5"
                        value={item.name}
                        onChange={(e) => {
                          const newItems = [...props.values.items];
                          newItems[index].name = e.target.value;
                          props.setFieldValue("items", newItems);
                        }}
                      /> */}
                    <SharedDropdown
                      label={t("unit")}
                      name={`items[${index}].unit`}
                      options={getUnitTypeOptions(lang)}
                      // options={unitTypeOptions}
                      value={item.unit}
                      onChange={(e) => {
                        const newItems = [...props.values.items];
                        newItems[index].unit = e.target.value;
                        props.setFieldValue("items", newItems);
                      }}
                    />
                    <Field
                      name={`items[${index}].price`}
                      as={SharedInputText}
                      label={`${t("item")} ${t("amount")}`}
                      type="number"
                      placeholder={`${t("enter")} ${t("amount")}`}
                      className="!p-1.5"
                      value={item.price}
                      onChange={(e) => {
                        const newItems = [...props.values.items];
                        newItems[index].price = Number(e.target.value);
                        props.setFieldValue("items", newItems);
                      }}
                    />
                    
                    <Field
                      name={`items[${index}].discount_amount`}
                      as={SharedInputText}
                      label={t("discountAmount")}
                      type="number"
                      placeholder={`${t("enter")} ${t("amount")}`}
                      className="!p-1.5"
                      value={item.discount_amount}
                      onChange={(e) => {
                        const newItems = [...props.values.items];
                        newItems[index].discount_amount = Number(
                          e.target.value
                        );
                        props.setFieldValue("items", newItems);
                      }}
                    />
                    
                    <div className="col-span-2 grid grid-cols-3 gap-1 w-full">
                      <Field
                        name={`items[${index}].quantity`}
                        as={SharedInputText}
                        label={t("quantity")}
                        type="number"
                        placeholder={`${t("enter")} ${t("quantity")}`}
                        className="!p-1.5"
                        value={item.quantity}
                        onChange={(e) => {
                          const newItems = [...props.values.items];
                          newItems[index].quantity = Number(e.target.value);
                          props.setFieldValue("items", newItems);
                        }}
                      />
                      <SharedDropdown
                        label={`${t("tax")} (15%)`}
                        name={`items[${index}].tax_check`}
                        options={getTaxCheckOptions(lang)}
                        value={item.tax_check}
                        onChange={(e) => {
                          const newItems = [...props.values.items];
                          newItems[index].tax_check = e.target.value;
                          props.setFieldValue("items", newItems);
                        }}
                      />
                      <div className="flex justify-end items-end">
                      <span className="h-[30px] w-full flex justify-end pr-5">
                        <RiDeleteBin5Line
                          size={20}
                          className="cursor-pointer text-red-500 my-auto"
                          onClick={() => {
                            const newItems = props.values.items.filter(
                              (_, i) => i !== index
                            );
                            props.setFieldValue("items", newItems);
                          }}
                        />
                      </span>
                      {/* </div> */}
                    </div>
                    </div>
                    {props.errors.items && props.errors.items[index] && (
                      <div className="col-span-4 text-red-500">
                        {Object.values(props.errors.items[index]).map(
                          (error, i) => (
                            <div key={i}>{error}</div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                ))}
                {props.errors.items &&
                  typeof props.errors.items === "string" && (
                    <div className="text-red-500">{props.errors.items}</div>
                  )}
              </div>

              <div className="mt-4 ">
                <CustomSearchInput
                  label={t("items")}
                  name={"items"}
                  options={ItemsOptions}
                  placeholder={`${t("select")} ${t("items")}`}
                  className="!w-full"
                  //   onChange={(e) => {
                  //     const selectedItem = ItemsOptions.find(
                  //       (item) => item.id === Number(e?.target?.value)
                  //     );
                  //     if (selectedItem) {
                  //       const newItem = {
                  //         item_id: selectedItem.id,
                  //         name_en: selectedItem.name_en,
                  //         name_ar: selectedItem.name_ar,
                  //         price: selectedItem.price,
                  //         unit: "0",
                  //         quantity: 1,
                  //         discount_amount: 0,
                  //         tax_check: true,
                  //       };
                  //       props.setFieldValue("items", [
                  //         ...props.values.items,
                  //         newItem,
                  //       ]);
                  //     }
                  //   }}
                  onChange={(selectedOption) => {
                    const selectedItem = ItemsOptions.find(
                      (item) => item.value === selectedOption.value
                    );
                    if (selectedItem) {
                      const newItem = {
                        item_id: selectedItem.value,
                        name_en: selectedItem.name_en,
                        name_ar: selectedItem.name_ar,
                        price: selectedItem.price,
                        unit: "0",
                        quantity: 1,
                        discount_amount: 0,
                        tax_check: true,
                      };
                      props.setFieldValue("items", [
                        ...props.values.items,
                        newItem,
                      ]);
                    }
                  }}
                />
              </div>

              <div className="flex justify-end gap-6 mt-6">
                <SharedButton
                  icon="pi pi-times"
                  label={t("Cancel")}
                  className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                  onClick={() => {
                    navigate("/manager/purchased_entry");
                    props.resetForm();
                  }}
                />
                <SharedButton
                  icon="pi pi-check"
                  label={t("Submit")}
                  className="!p-1.5 bg-gradient"
                  type="submit"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Loader showLoader={isLoading} />
    </div>
  );
}

// import React, { useEffect } from "react";
// import { Field, Formik } from "formik";
// import * as yup from "yup";
// import SharedInputText from "../../components/Shared/SharedInputText";
// import SharedButton from "../../components/Shared/SharedButton";
// import { useDispatch, useSelector } from "react-redux";
// import SharedDropdown from "../../components/Shared/SharedDropdown";
// import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
// import { getItems, getPriceListByID } from "../../store/slices/ItemSlice";
// import { RiDeleteBin5Line } from "react-icons/ri";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import {
//   CreateNewPurchasedEntry,
//   getPurchasedEntryList,
//   getVendors,
// } from "../../store/slices/PurchaseSlice";
// import i18next from "i18next";
// import Loader from "../../Loader";
// import { getExpenseAccounts } from "../../store/slices/AcountsSlice";
// import { getBranches } from "../../store/slices/BusinessSlice";

// export default function CreatePurchasedEntryPage() {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const lang = i18next.language;

//   useEffect(() => {
//     dispatch(getPriceListByID({ payer_id: "0" }));
//     dispatch(getVendors());
//     dispatch(getExpenseAccounts());
//   }, []);

//   const { expenseAccounts } = useSelector((state) => state.acount);
//   const { isLoading } = useSelector((state) => state.purchase);
//   const { items } = useSelector((state) => state.item);
//   const { vendors } = useSelector((state) => state.purchase);
//   const { user } = useSelector((state) => state.auth);

//   const listAccountsvalues = expenseAccounts?.data
//     ? Object.entries(expenseAccounts.data).map(([id, account]) => ({
//         id: Number(account?.code),
//         name: lang === "ar" ? account.name_ar : account.name_en,
//       }))
//     : [];
//   function convertData(inputData) {
//     return Object.values(inputData).map((item) => ({
//       id: item.ID,
//       name: lang === "ar" ? item.name_ar : item.name_en,
//       price: item.price,
//       name_en: item.name_en,
//       name_ar: item.name_ar,
//     }));
//   }
//   const ItemsOptions = convertData(items.data || []);

//   function vendor(inputData) {
//     return Object.values(inputData).map((item) => ({
//       id: item.id,
//       name: item.name,
//     }));
//   }

//   const taxCheckOptions = [
//     { value: true, label_en: "Yes", label_ar: "نعم" },
//     { value: false, label_en: "No", label_ar: "لا" },
//   ];
//   const getTaxCheckOptions = (lang) => {
//     return taxCheckOptions.map((option) => ({
//       value: option.value,
//       label: lang === "ar" ? option.label_ar : option.label_en,
//     }));
//   };

//   const unitTypeOptions = [
//     { value: "Service", label_en: "Service", label_ar: "خدمة" },
//     { value: "Kilogram", label_en: "Kilogram", label_ar: "كيلوجرام" },
//     { value: "Gram", label_en: "Gram", label_ar: "جرام" },
//     { value: "Milligram", label_en: "Milligram", label_ar: "ميليجرام" },
//   ];
//   const getUnitTypeOptions = (lang) => {
//     return unitTypeOptions.map((option) => ({
//       value: option.value,
//       label: lang === "ar" ? option.label_ar : option.label_en,
//     }));
//   };

//   const getLabel = (item) => {
//     const object = ItemsOptions.find((itm) => Number(itm.id) === item.item_id);
//     return object ? object.name : "";
//   };
//   const getAmount = (item) => {
//     const object = ItemsOptions.find((itm) => Number(itm.id) === item.item_id);
//     return object ? object.price : "";
//   };

//   const initialValues = {
//     vendor_id: "",
//     purchase_date: "",
//     notes: "",
//     refrence: "",
//     items: [],
//   };

//   const validationSchema = yup.object().shape({
//     vendor_id: yup.number().required(t("required")),
//     purchase_date: yup.string().required(t("required")),
//     notes: yup.string().required(t("required")),
//     refrence: yup.string().required(t("required")),
//     items: yup
//       .array()
//       .of(
//         yup.object().shape({
//           // item_id: yup.number().required(t("required")),
//           // name: yup.string().required(t("required")),

//           // unit: yup.string().required(t("required")),

//           // quantity: yup
//           //   .number()
//           //   .integer()
//           //   .min(1, "Quantity must be at least 1")
//           //   .required(t("required")),
//           price: yup.number().required(t("required")),
//           // account_code: yup.number().required(t("required")),
//           discount_amount: yup.number().required(t("required")),
//           tax_check: yup.bool().required(t("required")),
//         })
//       )
//       .min(1, t("required")),
//   });

//   return (
//     <div>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         onSubmit={async (data, { resetForm }) => {
//           const resultAction = await dispatch(CreateNewPurchasedEntry(data));
//           if (CreateNewPurchasedEntry.fulfilled.match(resultAction)) {
//             navigate("/manager/purchased_entry");
//             resetForm();
//             dispatch(getPurchasedEntryList({ page: 1, per_page: 10 }));
//           }
//         }}
//         validateOnChange={true}
//         validateOnBlur={true}
//         validate={(values) => {
//           validationSchema
//             .validate(values, { abortEarly: false })
//             .then(() => console.log("Validation passed"))
//             .catch((err) => console.log("Validation errors:", err.errors));
//         }}
//       >
//         {(props) => (
//           <form onSubmit={props.handleSubmit}>
//             <div className="rounded-md bg-gray-100 p-6 w-full gap-4">
//               <h2 className="text-3xl font-bold mb-6 text-gray-800">
//                 {`${t("CreatePurchasedEntry")}`}
//               </h2>

//               <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
//                 <CustomSearchDropdown
//                   name="vendor_id"
//                   placeholder={`${t("select")} ${t("vendor")}`}
//                   options={vendor(vendors || [])}
//                   label={t("vendors")}
//                   className={"!w-full"}
//                 />
//                 <SharedInputText
//                   type={"date"}
//                   label={t("purchase_date")}
//                   name={"purchase_date"}
//                   placeholder={`${"enter purchase_date"}`}
//                   className="!p-1.5"
//                 />

//                 <SharedInputText
//                   label={t("refrence")}
//                   name={"refrence"}
//                   placeholder={`${t("enter")} ${t("refrence")}`}
//                   className="!p-1.5"
//                 />

//                 <SharedInputText
//                   label={t("description")}
//                   name={"notes"}
//                   placeholder={`${t("enter")} ${t("description")}`}
//                   className="!p-1.5"
//                 />
//               </div>

//               <div className="mt-4">
//                 {props.values.items.map((item, index) => (
//                   <div
//                     key={index}
//                     className="grid grid-cols-8 gap-1 mb-4 border-t py-4"
//                   >
//                     {/* <div className="grid grid-cols-4 gap-4">
//                      */}

//                     <Field
//                       name={`items[${index}].name`}
//                       as={SharedInputText}
//                       label={`${t("item")} ${t("name")}`}
//                       type="text"
//                       placeholder={`${t("enter")} ${t("name")}`}
//                       className="!p-1.5"
//                       value={item.name_en}
//                       onChange={(e) => {
//                         const newItems = [...props.values.items];
//                         newItems[index].name_en = e.target.value;
//                         props.setFieldValue("items", newItems);
//                       }}
//                     />
//                     <Field
//                       name={`items[${index}].name`}
//                       as={SharedInputText}
//                       label={`${t("item")} ${t("name")}`}
//                       type="text"
//                       placeholder={`${t("enter")} ${t("name")}`}
//                       className="!p-1.5"
//                       value={item.name_ar}
//                       onChange={(e) => {
//                         const newItems = [...props.values.items];
//                         newItems[index].name_ar = e.target.value;
//                         props.setFieldValue("items", newItems);
//                       }}
//                     />
//                     {/* <Field
//                         name={`items[${index}].name`}
//                         as={SharedInputText}
//                         label={`${t("item")} ${t("name")}`}
//                         type="text"
//                         placeholder={`${t("enter")} ${t("name")}`}
//                         className="!p-1.5"
//                         value={item.name}
//                         onChange={(e) => {
//                           const newItems = [...props.values.items];
//                           newItems[index].name = e.target.value;
//                           props.setFieldValue("items", newItems);
//                         }}
//                       /> */}

//                     <Field
//                       name={`items[${index}].price`}
//                       as={SharedInputText}
//                       label={`${t("item")} ${t("amount")}`}
//                       type="number"
//                       placeholder={`${t("enter")} ${t("amount")}`}
//                       className="!p-1.5"
//                       value={item.price}
//                       onChange={(e) => {
//                         const newItems = [...props.values.items];
//                         newItems[index].price = Number(e.target.value);
//                         props.setFieldValue("items", newItems);
//                       }}
//                     />

//                     <Field
//                       name={`items[${index}].quantity`}
//                       as={SharedInputText}
//                       label={t(`quantity`)}
//                       type="number"
//                       placeholder={`${t("enter")} ${t("quantity")}`}
//                       className="!p-1.5"
//                       value={item.quantity}
//                       onChange={(e) => {
//                         const newItems = [...props.values.items];
//                         newItems[index].quantity = Number(e.target.value);
//                         props.setFieldValue("items", newItems);
//                       }}
//                     />

//                     <SharedDropdown
//                       label={t("unit")}
//                       name={`items[${index}].unit`}
//                       options={getUnitTypeOptions(lang)}
//                       // options={unitTypeOptions}
//                       value={item.unit}
//                       onChange={(e) => {
//                         const newItems = [...props.values.items];
//                         newItems[index].unit = e.target.value;
//                         props.setFieldValue("items", newItems);
//                       }}
//                     />

//                     <SharedDropdown
//                       label={`${t("tax")} ${t("(15%)")}`}
//                       name={`items[${index}].tax_check`}
//                       options={getTaxCheckOptions(lang)}
//                       // options={taxCheckOptions}
//                       value={item.tax_check}
//                       onChange={(e) => {
//                         const newItems = [...props.values.items];
//                         newItems[index].tax_check = e.target.value;
//                         props.setFieldValue("items", newItems);
//                       }}
//                     />

//                     <Field
//                       name={`items[${index}].discount_amount`}
//                       as={SharedInputText}
//                       label={t("discountAmount")}
//                       type="number"
//                       placeholder={`${t("enter")} ${t("amount")}`}
//                       className="!p-1.5"
//                       value={item.discount_amount}
//                       onChange={(e) => {
//                         const newItems = [...props.values.items];
//                         newItems[index].discount_amount = Number(
//                           e.target.value
//                         );
//                         props.setFieldValue("items", newItems);
//                       }}
//                     />
//                     <CustomSearchDropdown
//                       label={`${t("expense")} ${t("account")}`}
//                       className="!w-full"
//                       name={`items[${index}].account_code`}
//                       options={listAccountsvalues}
//                       placeholder={`${t("select")} ${t("account")}`}
//                     />

//                     <div className="flex justify-end items-end">
//                       <span className="h-[30px] w-full flex justify-end pr-5">
//                         <RiDeleteBin5Line
//                           size={20}
//                           className="cursor-pointer text-red-500 my-auto"
//                           onClick={() => {
//                             const newItems = props.values.items.filter(
//                               (_, i) => i !== index
//                             );
//                             props.setFieldValue("items", newItems);
//                           }}
//                         />
//                       </span>
//                       {/* </div> */}
//                     </div>
//                     {props.errors.items && props.errors.items[index] && (
//                       <div className="col-span-4 text-red-500">
//                         {Object.values(props.errors.items[index]).map(
//                           (error, i) => (
//                             <div key={i}>{error}</div>
//                           )
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 ))}
//                 {props.errors.items &&
//                   typeof props.errors.items === "string" && (
//                     <div className="text-red-500">{props.errors.items}</div>
//                   )}
//               </div>

//               <div className="mt-4 ">
//                 <CustomSearchDropdown
//                   label={t("items")}
//                   name={"items"}
//                   options={ItemsOptions}
//                   placeholder={`${t("select")} ${t("items")}`}
//                   className="!w-full"
//                   onChange={(e) => {
//                     const selectedItem = ItemsOptions.find(
//                       (item) => item.id === Number(e?.target?.value)
//                     );
//                     if (selectedItem) {
//                       const newItem = {
//                         item_id: selectedItem.id,
//                         name_en: selectedItem.name_en,
//                         name_ar: selectedItem.name_ar,
//                         price: selectedItem.price,
//                         unit: "0",
//                         quantity: 1,
//                         discount_amount: 0,
//                         tax_check: true,
//                       };
//                       props.setFieldValue("items", [
//                         ...props.values.items,
//                         newItem,
//                       ]);
//                     }
//                   }}
//                 />
//               </div>

//               <div className="flex justify-end gap-6 mt-6">
//                 <SharedButton
//                   icon="pi pi-times"
//                   label={t("Cancel")}
//                   className="!p-1.5 !bg-red-400 hover:!bg-red-400"
//                   onClick={() => {
//                     navigate("/manager/purchased_entry");
//                     props.resetForm();
//                   }}
//                 />
//                 <SharedButton
//                   icon="pi pi-check"
//                   label={t("Submit")}
//                   className="!p-1.5 bg-gradient"
//                   type="submit"
//                 />
//               </div>
//             </div>
//           </form>
//         )}
//       </Formik>
//     </div>
//   );
// }
