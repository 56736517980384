import React from "react";
import { Paginator } from "primereact/paginator";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Ripple } from "primereact/ripple";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";

function CustomPagination({
  currentPage,
  rowsPerPage,
  totalRecords,
  onPageChange,
  totalRecord,
}) {
  const { t } = useTranslation();

  const handlePageChange = (event) => {
    onPageChange(event.page + 1, event.rows);
  };

  const template = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={classNames(options.className, "border-round")}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3 flex justify-center gap-2 items-center">
            {<FaArrowLeftLong />}
            {t("previous")}
          </span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={classNames(options.className, "border-round")}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3 flex justify-center gap-2 items-center">
            {t("next")}
            {<FaArrowRightLong />}
          </span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });
        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 1, value: 1 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
      ];
      return (
        <Dropdown
          value={options.value}
          options={dropdownOptions}
          onChange={options.onChange}
          className="!w-20"
          pt={{
            root: { className: "w-full md:w-full p-primary-input" },
            input: { className: "w-full p-primary-input !p-0 !px-1" },
            filterInput: { className: "mr-0" },
            filterIcon: {
              className: "rtl:right-auto rtl:left-4 dark:text-white",
            },
          }}
        />
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span className="mx-3">{t(`${t("TotalRecords")} ${totalRecord}`)}</span>
      );
    },
  };

  return (
    <div>
      <Paginator
        first={(currentPage - 1) * rowsPerPage}
        rows={rowsPerPage}
        totalRecords={totalRecords * rowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 30]}
        onPageChange={handlePageChange}
        template={template}
        className="!bg-transparent !mt-1"
        pt={{
          pages: { className: "flex gap-4 items-center" },
        }}
      />
    </div>
  );
}

export default CustomPagination;
