import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import SharedTooltip from "../components/Shared/SharedTooltip";
import { IoMdEye } from "react-icons/io";
import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../components/Shared/CustomPagination";
import {
  deleteBuisness,
  GetBusinessesList,
} from "../store/slices/BusinessSlice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import BusinessModal from "./BusinessModal";

export default function BusinessTable({ data }) {
  const { isLoading } = useSelector((state) => state.business);
  const dispatch = useDispatch();
  const lang = i18next.language;

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(GetBusinessesList({ page: newPage, per_page: newRowsPerPage }));
  };
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const { t } = useTranslation();
  const templateBody = (rowData) => {
    return (
      <div className="flex  gap-1">
        <SharedTooltip content="view" id="my-tooltip-1" />
        <SharedTooltip id="my-tooltip-2" content="edit" />
        <SharedTooltip id="my-tooltip-3" content="delete" />
        {/* <IoMdEye
          size={25}
          data-tooltip-id="my-tooltip-1"
          className="!p-1 !text-black hover:cursor-pointer"
        /> */}
        <MdOutlineEdit
          size={25}
          data-tooltip-id="my-tooltip-2"
          className="!p-1 !text-black hover:cursor-pointer"
          onClick={() => {
            setEditData(rowData);
            setCreateDialogVisible(true);
          }}
        />
        <MdOutlineDelete
          size={25}
          data-tooltip-id="my-tooltip-3"
          className="!p-1 !text-black hover:cursor-pointer"
          onClick={() => {
            deleteBuisness({ id: rowData?.id });
          }}
        />
      </div>
    );
  };
  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };
  return (
    <>
      <DataTable value={data?.data} tableStyle={{ minWidth: "50rem" }}>
        <Column
          body={sequenceBodyTemplate}
          header={t("no")}
          style={{ width: "3rem" }}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          field={lang === "ar" ? "name_ar" : "name_en"}
          header={t("name")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="vat_no"
          header={t("vatNo")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary ",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="cr_no"
          header={t("crNo")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary ",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="contact_info"
          header={t("contact")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          body={templateBody}
          header={t("actions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
      </DataTable>
      <Loader showLoader={isLoading} />
      <BusinessModal
        setCreateDialogVisible={setCreateDialogVisible}
        createDialogVisible={createDialogVisible}
        dispatch={dispatch}
        editData={editData}
      />
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
