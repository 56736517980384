import React, { useState } from "react";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";

export default function Home() {
  const [darkMode, setDarkMode] = useState(false);

  const revenueData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Revenue",
        data: [65000, 59000, 80000, 81000, 56000, 55000],
        fill: false,
        borderColor: "#4CAF50",
        tension: 0.4,
      },
      {
        label: "Expenses",
        data: [28000, 48000, 40000, 19000, 86000, 27000],
        fill: false,
        borderColor: "#FFA726",
        tension: 0.4,
      },
    ],
  };

  const accountBalances = [
    { account: "Cash", balance: 50000 },
    { account: "Accounts Receivable", balance: 75000 },
    { account: "Inventory", balance: 100000 },
    { account: "Accounts Payable", balance: 30000 },
    { account: "Long-term Debt", balance: 200000 },
  ];

  const recentTransactions = [
    {
      id: 1,
      date: "2024-05-10",
      description: "Sales Revenue",
      amount: 5000,
      type: "Credit",
    },
    {
      id: 2,
      date: "2024-05-09",
      description: "Office Supplies",
      amount: 500,
      type: "Debit",
    },
    {
      id: 3,
      date: "2024-05-08",
      description: "Loan Payment",
      amount: 2000,
      type: "Debit",
    },
    {
      id: 4,
      date: "2024-05-07",
      description: "Consulting Fees",
      amount: 3000,
      type: "Credit",
    },
    {
      id: 5,
      date: "2024-05-06",
      description: "Utility Bill",
      amount: 800,
      type: "Debit",
    },
  ];

  const formatCurrency = (value) => {
    return `$${value.toLocaleString()}`;
  };

  const typeBodyTemplate = (rowData) => {
    return (
      <span
        className={`px-2 py-1 rounded ${
          rowData.type === "Credit" ? "bg-green-500" : "bg-red-500"
        } text-white`}
      >
        {rowData.type}
      </span>
    );
  };

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900 text-white" : "transparent"
      }`}
    >
      <div className="container mx-auto px-4 py-8">
        {/* <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-bold">Financial Dashboard</h1>
          <InputSwitch
            checked={darkMode}
            onChange={(e) => setDarkMode(e.value)}
          />
        </div> */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <Card title="Total Revenue" className={darkMode ? "bg-gray-800" : ""}>
            <p className="text-2xl font-bold">{formatCurrency(396000)}</p>
          </Card>
          <Card
            title="Total Expenses"
            className={darkMode ? "bg-gray-800" : ""}
          >
            <p className="text-2xl font-bold">{formatCurrency(248000)}</p>
          </Card>
          <Card title="Net Profit" className={darkMode ? "bg-gray-800" : ""}>
            <p className="text-2xl font-bold">{formatCurrency(148000)}</p>
          </Card>
          <Card title="Cash Flow" className={darkMode ? "bg-gray-800" : ""}>
            <p className="text-2xl font-bold">{formatCurrency(50000)}</p>
          </Card>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          <Card
            title="Revenue vs Expenses"
            className={darkMode ? "bg-gray-800" : ""}
          >
            <Chart type="line" data={revenueData} />
          </Card>
          <Card
            title="Account Balances"
            className={darkMode ? "bg-gray-800" : ""}
          >
            <DataTable value={accountBalances} className="p-datatable-sm">
              <Column field="account" header="Account" />
              <Column
                field="balance"
                header="Balance"
                body={(rowData) => formatCurrency(rowData.balance)}
              />
            </DataTable>
          </Card>
        </div>

        <Card
          title="Recent Transactions"
          className={`mb-6 ${darkMode ? "bg-gray-800" : ""}`}
        >
          <DataTable
            value={recentTransactions}
            className="p-datatable-sm"
            rows={5}
            paginator
          >
            <Column field="date" header="Date" />
            <Column field="description" header="Description" />
            <Column
              field="amount"
              header="Amount"
              body={(rowData) => formatCurrency(rowData.amount)}
            />
            <Column field="type" header="Type" body={typeBodyTemplate} />
            <Column
              body={() => (
                <Button
                  icon="pi pi-eye"
                  className="p-button-rounded p-button-text"
                />
              )}
            />
          </DataTable>
        </Card>
      </div>
    </div>
  );
}
