import i18next from "i18next";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import React from "react";
import { useTranslation } from "react-i18next";


export default function VatReturnReportTable({ data }) {
  const { t } = useTranslation();
  const lang = i18next.language;
  // const data = {
  //   data: [
  //     { id: 1, account_code: 'AC001', tax: '5%', amount: 100.00, adjustment: 5.00, vatAmount: 10.00, description: 'Service A' },
  //     { id: 2, account_code: 'AC002', tax: '10%', amount: 200.00, adjustment: 10.00, vatAmount: 20.00, description: 'Service B' },
  //     { id: 3, account_code: 'AC003', tax: '15%', amount: 300.00, adjustment: 15.00, vatAmount: 45.00, description: 'Service C' },
  //     { id: 4, account_code: 'AC004', tax: '20%', amount: 400.00, adjustment: 20.00, vatAmount: 80.00, description: 'Service D' },
  //     { id: 5, account_code: 'AC005', tax: '25%', amount: 500.00, adjustment: 25.00, vatAmount: 125.00, description: 'Service E' },
  //     { id: 6, account_code: 'AC006', tax: '5%', amount: 150.00, adjustment: 7.50, vatAmount: 15.00, description: 'Service F' },
  //     { id: 7, account_code: 'AC007', tax: '10%', amount: 250.00, adjustment: 12.50, vatAmount: 25.00, description: 'Service G' },
  //     { id: 8, account_code: 'AC008', tax: '15%', amount: 350.00, adjustment: 17.50, vatAmount: 52.50, description: 'Service H' },
  //     { id: 9, account_code: 'AC009', tax: '20%', amount: 450.00, adjustment: 22.50, vatAmount: 90.00, description: 'Service I' },
  //     { id: 10, account_code: 'AC010', tax: '25%', amount: 550.00, adjustment: 27.50, vatAmount: 137.50, description: 'Service J' }
  //   ]
  // };
  const tableData = data?.data;
  const indexedData = tableData
    ? tableData.map((item, index) => ({ ...item, index: index + 1 }))
    : [];

  return (
    <>
      <div className="">
        <div className="w-full md:w-9/12 m-auto">
          <div className=" bg-white rounded px-4 py-2 mb-4 border">
            <div className="text-lg flex justify-between py-2 border-b">
              <span className="font-semibold">{t("Taxpayer Name")}:</span>{" "}
              <span className="">
                {/* {data?.taxpayer_name_en} */}
                {lang === "ar"
                  ? data?.taxpayer_name_ar
                  : data?.taxpayer_name_en}
              </span>
            </div>
            <div className="text-lg flex justify-between py-2 border-b">
              <span className="font-semibold">
                {t("VAT Registration Number")}:
              </span>{" "}
              <span className="">{data?.vat_registration}</span>
            </div>
            <div className="text-lg flex justify-between py-2 border-b">
              <span className="font-semibold">
                {t("Effective Registration Date")}:
              </span>{" "}
              <span className="">{data?.registration_date}</span>
            </div>
            <div className="text-lg flex justify-between py-2">
              <span className="font-semibold">{t("Taxpayer Address")}:</span>{" "}
              <span className="">{data?.address}</span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-9/12 m-auto">
          <div className="bg-white !rounded mt-4">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border p-3">{t("tax")}</th>
                  <th className="border p-3">{t("amount")}</th>
                  <th className="border p-3">{t("adjustment")}</th>
                  <th className="border p-3">{t("Tax Amount")}</th>
                </tr>
              </thead>
              <tbody>
                {/* Sales */}
                {/* Sales Row */}
                <tr>
                  <td className="border p-3"><b>{t("Sales")}</b></td>
                  <td className="border p-3">{data?.all_sales?.toFixed(2) || "0"}{" "}
                  {`${t("SAR")}`}</td>
                  <td className="border p-3">
                    {t("Credit notes issued this period: -0.00")}
                  </td>
                  <td className="border p-3">{data?.all_sales_vat?.toFixed(2) || "0"}{" "}
                  {`${t("SAR")}`}</td>
                </tr>
                {/* Zero Tax Row */}
                <tr>
                  <td className="border p-3">{t("Zero Tax")}</td>
                  <td className="border p-3">0.00</td>
                  <td className="border p-3">-0.00</td>
                  <td className="border p-3">0.00</td>
                </tr>
                {/* Tax Exempt Row */}
                <tr>
                  <td className="border p-3">{t("Tax Exempt")}</td>
                  <td className="border p-3">-{data?.tax_exempt_sale?.toFixed(2) || "0"}{" "}
                  {`${t("SAR")}`}</td>
                  <td className="border p-3">-0.00</td>
                  <td className="border p-3">-{data?.tax_exempt_vat?.toFixed(2) || "0"}{" "}
                  {`${t("SAR")}`}</td>
                </tr>
                {/* Sales Total */}
                <tr className="bg-gray-100">
                  <td className="border p-3">
                    {t("Total Sale")}
                  </td>
                  <td className="border p-3">{data?.total_sale?.toFixed(2) || "0"}{" "}
                  {`${t("SAR")}`}</td>
                  <td className="border p-3">0.00</td>
                  <td className="border p-3">{data?.total_sale_vat?.toFixed(2) || "0"}{" "}
                  {`${t("SAR")}`}</td>
                </tr>

                {/* Purchase */}
                {/* Purchase Row */}
                <tr>
                  <td className="border p-3"><b>{t("purchase")}</b></td>
                  <td className="border p-3">{data?.all_purchases?.toFixed(2) || "0"}{" "}
                  {`${t("SAR")}`}</td>
                  <td className="border p-3">
                    {t("Credit notes issued this period: -0.00")}
                  </td>
                  <td className="border p-3">{data?.all_purchases_vat?.toFixed(2) || "0"}{" "}
                  {`${t("SAR")}`}</td>
                </tr>
                {/* Zero Tax Row */}
                {/* <tr>
                  <td className="border p-3">Zero Tax</td>
                  <td className="border p-3">0.00</td>
                  <td className="border p-3">-0.00</td>
                  <td className="border p-3">0.00</td>
                </tr> */}
                {/* Tax Exempt Row */}
                {/* <tr>
                  <td className="border p-3">Tax Exempt</td>
                  <td className="border p-3">0.00</td>
                  <td className="border p-3">0.00</td>
                  <td className="border p-3">0.00</td>
                </tr> */}
                {/* Purchase Total */}
                {/* <tr className="bg-gray-100">
                  <td className="border p-3">
                    Total Purchase
                  </td>
                  <td className="border p-3">0.00</td>
                  <td className="border p-3">0.00</td>
                  <td className="border p-3">0.00</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        {/* 
        <div className="bg-white !rounded-tl-md !rounded-tr-md">
          <div className="grid grid-cols-12 bg-secondary font-bold py-1 !rounded-tl-md !rounded-tr-md">
            <div className="col-span-1 px-1.5">{t("no")}</div>
            <div className="col-span-2">{t("tax")}</div>
            <div className="col-span-2">{`${t("amount")} ${t("type")}`}</div>
            <div className="col-span-2">{t("Adjustment")}</div>
            <div className="col-span-2">{t("VAT Amount")}</div>
            <div className="col-span-3">{t("description")}</div>
          </div>

          {indexedData.map((asset) => (
            <div
              key={asset.id}
              className="grid grid-cols-12 items-center py-1 border-b last:border-b-0"
            >
              <div className="col-span-1 px-1.5">{asset.index}</div>
              <div className="col-span-2">
                {asset.account_code}-{asset.tax}
              </div>

              <div className="col-span-2">
                {asset.amount}{" "}
                {`${t("SAR")}`}
              </div>
              <div className="col-span-2">
                {asset?.adjustment
                  ? asset.adjustment.toFixed(2)
                  : "0"}{" "}
                {`${t("SAR")}`}
              </div>
              <div className="col-span-2">
                {asset?.vatAmount
                  ? asset.vatAmount.toFixed(2)
                  : "0"}{" "}
                {`${t("SAR")}`}
              </div>

              <div className="col-span-3">
                {asset?.description }
              </div>

            </div>
          ))}
        </div> */}

        <div className="w-full md:w-9/12 m-auto">
          <div className=" bg-white rounded px-4 py-2 mt-4 border">
            {/* <div className="text-lg flex justify-between py-2 border-b">
              <span className="font-semibold">{t("Total Sales (Saudi)")}:</span>{" "}
              <span className="">
                {data?.total_sales_saudi_nationality?.toFixed(2) || "0"}{" "}
                {`${t("SAR")}`}
              </span>
            </div>
            <div className="text-lg flex justify-between py-2 border-b">
              <span className="font-semibold">
                {t("Total Sales(Non-Saudi)")}:
              </span>{" "}
              <span className="">
                {data?.total_sales_excluding_saudi?.toFixed(2) || "0"}{" "}
                {`${t("SAR")}`}
              </span>
            </div> */}

            <div className="text-lg flex justify-between py-2 border-b">
              <span className="font-semibold">{t("Net Purchase")}:</span>{" "}
              <span className="">
                {data?.all_purchases?.toFixed(2) || "0"} {`${t("SAR")}`}
              </span>
            </div>
            <div className="text-lg flex justify-between py-2 border-b">
              <span className="font-semibold">{t("Net Sales")}:</span>{" "}
              <span className="">
                {data?.total_sale?.toFixed(2) || "0"} {`${t("SAR")}`}
              </span>
            </div>

            <div className="text-lg flex justify-between py-2">
              <span className="font-semibold">
                {t("Net VAT")}:
              </span>{" "}
              <span className="">
                {(data?.total_sale_vat + data?.all_purchases_vat)?.toFixed(2) || "0"}{" "}
                {`${t("SAR")}`}
              </span>
            </div>

          </div>
        </div>
      </div>

      {/* <div className="">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-6 ">
          <div className="card shadow-md px-3.5 py-3 rounded md:col-start-2 lg:col-start-3 text-center lg:text-right">
            <h2 className="font-bold text-lg mb-2 px-8 text-gray-800 !text-center">
              {t("Sub Totals")}
            </h2>
            <Divider className="!mb-3.5 !mt-0" />
            <p className="text-gray-700 text-sm mb-2 flex justify-between">
              <span className="font-semibold">{t("Total Credit")}:</span>{" "}
              <span className="text-sm">
                {totalAmont?.totalCredit || "___"} {`${t("SAR")}`}
              </span>
            </p>
            <p className="text-gray-700 text-sm flex justify-between">
              <span className="font-semibold">{t("Total Debit")}:</span>{" "}
              <span className="text-sm">
                {totalAmont?.totalDebit || "___"} {`${t("SAR")}`}
              </span>
            </p>
            <p className="text-gray-700 text-sm my-2 flex justify-between">
              <span className="font-semibold">{t("Total Balance")}:</span>{" "}
              <span className="text-sm">
                {totalAmont?.totalBalance
                  ? totalAmont.totalBalance.toFixed(2)
                  : "___"}{" "}
                {`${t("SAR")}`}
              </span>
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}
