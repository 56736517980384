import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useTranslation } from "react-i18next";

export default function VatReportTableCompanies({ data }) {

  const indexedData = data
    ? data.map((item, index) => ({ ...item, index: index + 1 }))
    : [];
    
  const { t } = useTranslation();

  const calculateTotals = () => {
    let companyVatTotal = 0;

    indexedData.forEach((item) => {
      companyVatTotal += item.TotalVAT || 0;
    });

    return {
      companyVatTotal: companyVatTotal.toFixed(2),
    };
  };
  const totals = calculateTotals();

  return (
    <div className="">
      <div className="bg-white !rounded-tl-md !rounded-tr-md">
        <div className="grid grid-cols-12 !bg-secondary font-bold py-1 !rounded-tl-md !rounded-tr-md">
          <div className="col-span-1 px-1.5">{t("no")}</div>
          <div className="col-span-6">{t("name")}</div>
          <div className="col-span-5">{t("totalVAT")}</div>
        </div>
        {indexedData.map((asset) => (
          <div
            key={asset.id}
            className="grid grid-cols-12 items-center py-1 border-b last:border-b-0"
          >
            <div className="col-span-1 px-1.5">{asset.index}</div>
            <div className="col-span-6">{asset.CompanyName}</div>
            <div className="col-span-5">
              {asset.TotalVAT ? asset.TotalVAT.toFixed(2) : "0"}{" "}
              <span className="text-sm">{`${t("SAR")}`}</span>
            </div>
          </div>
        ))}

        <div className="total grid grid-cols-12 items-center py-1 border-t bg-gray-100">
            <div className="col-span-1 px-1.5"></div>
            <div className="col-span-6 font-semibold text-black/70 text-lg">
              {t("Total")}
            </div>
            <div className="col-span-5 font-semibold text-black/60 text-lg">
              {totals.companyVatTotal} {`${t("SAR")}`}
            </div>
          </div>
      </div>

      {/* <DataTable value={indexedData} tableStyle={{ minWidth: "50rem" }}>
        <Column
          field="index"
          header={t("no")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="CompanyName"
          header={t("companyName")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="TotalVAT"
          header={t("totalVAT")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        
      </DataTable> */}
    </div>
  );
}
