import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

const TreeNode = ({ node, level }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();

  const hasChildren = node.sub_ledgers && node.sub_ledgers.length > 0;
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <div
        className={`grid grid-cols-12 items-center py-2 border-b px-4 ${
          level === 0 ? "font-medium" : ""
        }`}
      >
        <div
          className={`col-span-9 flex items-center`}
          style={{ paddingLeft: `${level * 20}px` }}
        >
          {hasChildren && (
            <button onClick={toggleOpen} className="mr-2">
              {isOpen ? (
                <IoIosArrowDown size={16} />
              ) : (
                <IoIosArrowForward size={16} />
              )}
            </button>
          )}
          <span>{node.name_en}</span>
        </div>
        <div className={`col-span-3`}>
          {node.balance ? node.balance.toFixed(2) : "0"} <span>{t("SAR")}</span>
        </div>
      </div>
      {isOpen &&
        hasChildren &&
        node.sub_ledgers.map((child, index) => (
          <TreeNode
            key={child.account?.id || index}
            node={child.account || child}
            level={level + 1}
          />
        ))}
    </>
  );
};

const LedgerTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="py-2 text-xl font-semibold">1 - {t("assets")}</div>
      <div className="bg-white rounded-lg shadow overflow-hidden my-2">
        <div className="grid grid-cols-12 bg-gray-200 font-bold py-2 px-4">
          <div className="col-span-9">{t("name")}</div>
          <div className="col-span-3">{t("balance")}</div>
        </div>
        {data.map((account, index) => (
          <TreeNode key={account.account.id} node={account.account} level={0} />
        ))}
      </div>
    </>
  );
};

export default LedgerTable;

// import i18next from "i18next";
// import { Column } from "primereact/column";
// import { DataTable } from "primereact/datatable";
// import React from "react";
// import { useTranslation } from "react-i18next";

// export default function LedgerTable({ data }) {
//   const lang = i18next.language;
//   const indexedData = data
//     ? data.map((item, index) => ({ ...item, index: index + 1 }))
//     : [];

//   const { t } = useTranslation();

//   if (!indexedData || !indexedData) {
//     return <div>No ledger data available</div>;
//   }

//   return (
//     <>
//       <div className="bg-white !rounded-tl-md !rounded-tr-md">
//         <div className="grid grid-cols-12 bg-secondary font-bold py-1 !rounded-tl-md !rounded-tr-md">
//           <div className="col-span-1 px-1.5">{t("no")}</div>
//           <div className="col-span-2">{t("date")}</div>
//           <div className="col-span-3">{t("name")}</div>
//           <div className="col-span-2">{t("debit")}</div>
//           <div className="col-span-2">{t("credit")}</div>
//           <div className="col-span-2">{t("balance")}</div>
//         </div>
//         {indexedData.map((asset) => (
//           <div
//             key={asset.id}
//             className="grid grid-cols-12 items-center py-1 border-b last:border-b-0"
//           >
//             <div className="col-span-1 px-1.5">{asset.index}</div>
//             {/* <div className="col-span-2">{asset.date}</div>
//             <div className="col-span-3">
//               {lang === "ar"
//                 ? `${asset.accounts.name_ar}`
//                 : `${asset.accounts.name_en}`}
//             </div>

//             <div className="col-span-2">
//               {asset.credit} <span className="text-sm">{`${t("SAR")}`}</span>
//             </div>
//             <div className="col-span-2">
//               {asset.debit} <span className="text-sm">{`${t("SAR")}`}</span>
//             </div>
//             <div className="col-span-2">
//               {asset.balance} <span className="text-sm">{`${t("SAR")}`}</span>
//             </div> */}
//           </div>
//         ))}
//       </div>
//     </>
//   );
// }

//   {/* <div className="mt-5">
//   <DataTable value={indexedData} tableStyle={{ minWidth: "50rem" }}>
//     <Column
//       field="index"
//       header={t("no")}
//       pt={{
//         headerCell: {
//           className: "!p-[0.3rem] !bg-secondary",
//         },
//         bodyCell: {
//           className:
//             "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
//         },
//       }}
//     />
//     <Column
//       field="date"
//       header={t("date")}
//       pt={{
//         headerCell: {
//           className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
//         },
//         bodyCell: {
//           className:
//             "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
//         },
//       }}
//     />
//     <Column
//       field={(rowData) =>
//         rowData.accounts
//           ? lang === "ar"
//             ? rowData.accounts.name_ar
//             : rowData.accounts.name_en
//           : ""
//       }
//       header={t("name")}
//       pt={{
//         headerCell: {
//           className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
//         },
//         bodyCell: {
//           className:
//             "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
//         },
//       }}
//     />
//     <Column
//       field="debit"
//       header={t("debit")}
//       pt={{
//         headerCell: {
//           className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
//         },
//         bodyCell: {
//           className:
//             "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
//         },
//       }}
//     />
//     <Column
//       field="credit"
//       header={t("credit")}
//       pt={{
//         headerCell: {
//           className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
//         },
//         bodyCell: {
//           className:
//             "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
//         },
//       }}
//     />
//     <Column
//       field="balance"
//       header={t("balance")}
//       pt={{
//         headerCell: {
//           className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
//         },
//         bodyCell: {
//           className:
//             "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
//         },
//       }}
//     />
//   </DataTable>
// </div> */}
