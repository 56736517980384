// ---openAndCollapsable---
import React, { useEffect, useState } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { IoMdEye } from "react-icons/io";
import { MdOutlineEdit } from "react-icons/md";
import { MdOutlineDelete } from "react-icons/md";
import SharedTooltip from "../../components/Shared/SharedTooltip";
// import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { deleteAccount, getAccounts } from "../../store/slices/AcountsSlice";
import { useDispatch } from "react-redux";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import SharedButton from "../../components/Shared/SharedButton";
import { Dialog } from "primereact/dialog";

export default function AccountsTable({
  data,
  setUpdateData,
  setCreateDialogVisible,
  activeTab,
}) {
  const lang = i18next.language;
  const { t } = useTranslation();
  const [confirmationDialogVisible, setConfirmationDialogVisible] =
    useState(false);
  const [expandedKeys, setExpandedKeys] = useState({});
  const dispatch = useDispatch();
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    const expandAll = (nodes, expandedKeys = {}) => {
      for (let node of nodes) {
        expandedKeys[node.key] = true;
        if (node.children) {
          expandAll(node.children, expandedKeys);
        }
      }
      return expandedKeys;
    };

    setExpandedKeys(expandAll(data));
  }, [data]);

  const templateBody = (rowData) => {
    return (
      <div className="flex gap-1">
        <SharedTooltip content="view" id="my-tooltip-1" />
        <SharedTooltip id="my-tooltip-2" content="edit" />
        <SharedTooltip id="my-tooltip-3" content="delete" />
        <IoMdEye
          size={25}
          data-tooltip-id="my-tooltip-1"
          className="!p-1 !text-black cursor-pointer"
        />
        <MdOutlineEdit
          size={25}
          data-tooltip-id="my-tooltip-2"
          className="!p-1 !text-black cursor-pointer"
          onClick={() => {
            setUpdateData(rowData?.data);
            setCreateDialogVisible(true);
          }}
        />
        <MdOutlineDelete
          onClick={() => {
            setConfirmationDialogVisible(true);
            setDeleteData(rowData);
          }}
          size={25}
          data-tooltip-id="my-tooltip-3"
          className="!p-1 !text-black cursor-pointer"
        />
      </div>
    );
  };

  const balanceBody = (data) => {
    return (
      <>
        {/* {data?.data?.balance} {t("SAR")} */}
        {data?.data?.balance ? data?.data?.balance.toFixed(2) : "0"}{" "}
        {`${t("SAR")}`}
      </>
    );
  };

  return (
    <div>
      <TreeTable
        value={data}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        emptyMessage={
          <div className="text-center w-full py-4 text-gray-500">
            {t("No user found")}
          </div>
        }
        pt={{
          wrapper: { className: "overflow-auto" },
          table: { className: "min-w-[50rem]" },
        }}
      >
        <Column
          field={lang === "ar" ? "name_ar" : "name_en"}
          header={t("name")}
          expander
          pt={{
            headerCell: {
              className: "!p-[0.31rem] !bg-secondary !rounded-tl-md !w-[40%]",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[40%]",
            },
          }}
        ></Column>

        <Column
          body={balanceBody}
          field="balance"
          header={t("balance")}
          pt={{
            headerCell: { className: "!p-[0.3rem] !bg-secondary !w-[15%]" },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
            },
          }}
        ></Column>

        <Column
          field={lang === "ar" ? "type_ar" : "type_en"}
          header={t("type")}
          pt={{
            headerCell: { className: "!p-[0.3rem] !bg-secondary !w-[15%]" },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
            },
          }}
        ></Column>

        <Column
          field={lang === "ar" ? "sub_type_ar" : "sub_type_en"}
          header={t("subType")}
          pt={{
            headerCell: { className: "!p-[0.3rem] !bg-secondary !w-[15%]" },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
            },
          }}
        ></Column>

        <Column
          body={templateBody}
          header={t("actions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md !w-[15%]",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
            },
          }}
        ></Column>
      </TreeTable>
      <Dialog
        header={t("confirmation")}
        visible={confirmationDialogVisible}
        onHide={() => {
          setConfirmationDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-6/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <div>
          <p>Do you really want to Delete</p>
        </div>

        <div className="flex justify-end gap-6 mt-6">
          <SharedButton
            icon="pi pi-times"
            label={t("Cancel")}
            className="!p-1.5 !bg-red-400 hover:!bg-red-400"
            onClick={() => {
              setConfirmationDialogVisible(false);
            }}
          />
          <SharedButton
            type="submit"
            icon="pi pi-check"
            label={t("Submit")}
            className="!p-1.5 bg-gradient"
            onClick={() => {
              dispatch(deleteAccount(deleteData?.data?.id)).then(() => {
                dispatch(getAccounts({ account_type: activeTab }));
              });
              setConfirmationDialogVisible(false);
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}

// // ---openAndCollapsable---
// import React, { useEffect, useState } from "react";
// import { TreeTable } from "primereact/treetable";
// import { Column } from "primereact/column";
// import { IoMdEye } from "react-icons/io";
// import { MdOutlineEdit } from "react-icons/md";
// import { MdOutlineDelete } from "react-icons/md";
// import SharedTooltip from "../../components/Shared/SharedTooltip";
// // import Loader from "../../Loader";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";

// export default function AccountsTable({ data }) {
//   const lang = i18next.language;
//   const { t } = useTranslation();

//   const [expandedKeys, setExpandedKeys] = useState({});

//   useEffect(() => {
//     const expandAll = (nodes, expandedKeys = {}) => {
//       for (let node of nodes) {
//         expandedKeys[node.key] = true;
//         if (node.children) {
//           expandAll(node.children, expandedKeys);
//         }
//       }
//       return expandedKeys;
//     };

//     setExpandedKeys(expandAll(data));
//   }, [data]);

//   const templateBody = (rowData) => {
//     return (
//       <div className="flex gap-1">
//         <SharedTooltip content="view" id="my-tooltip-1" />
//         <SharedTooltip id="my-tooltip-2" content="edit" />
//         <SharedTooltip id="my-tooltip-3" content="delete" />
//         <IoMdEye
//           size={25}
//           data-tooltip-id="my-tooltip-1"
//           className="!p-1 !text-black hover:cursor-pointer"
//         />
//         <MdOutlineEdit
//           size={25}
//           data-tooltip-id="my-tooltip-2"
//           className="!p-1 !text-black hover:cursor-pointer"
//         />
//         <MdOutlineDelete
//           size={25}
//           data-tooltip-id="my-tooltip-3"
//           className="!p-1 !text-black hover:cursor-pointer"
//         />
//       </div>
//     );
//   };

//   const balanceBody = (data) => {
//     return (
//       <>
//         {/* {data?.data?.balance} {t("SAR")} */}
//         {data?.data?.balance ? data?.data?.balance.toFixed(2) : "0"}{" "}
//         {`${t("SAR")}`}
//       </>
//     );
//   };

//   return (
//     <div>
//       <TreeTable
//         value={data}
//         expandedKeys={expandedKeys}
//         onToggle={(e) => setExpandedKeys(e.value)}
//         emptyMessage={
//           <div className="text-center w-full py-4 text-gray-500">
//             {t("No user found")}
//           </div>
//         }
//         pt={{
//           wrapper: { className: "overflow-auto" },
//           table: { className: "min-w-[50rem]" },
//         }}
//       >
//         <Column
//           field={lang === "ar" ? "name_ar" : "name_en"}
//           header={t("name")}
//           expander
//           pt={{
//             headerCell: {
//               className: "!p-[0.3rem] !bg-secondary !rounded-tl-md !w-[40%]",
//             },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[40%]",
//             },
//           }}
//         ></Column>

//         <Column
//           body={balanceBody}
//           field="balance"
//           header={t("balance")}
//           pt={{
//             headerCell: { className: "!p-[0.3rem] !bg-secondary !w-[15%]" },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
//             },
//           }}
//         ></Column>

//         <Column
//           field={lang === "ar" ? "type_ar" : "type_en"}
//           header={t("type")}
//           pt={{
//             headerCell: { className: "!p-[0.3rem] !bg-secondary !w-[15%]" },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
//             },
//           }}
//         ></Column>

//         <Column
//           field={lang === "ar" ? "sub_type_ar" : "sub_type_en"}
//           header={t("subType")}
//           pt={{
//             headerCell: { className: "!p-[0.3rem] !bg-secondary !w-[15%]" },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
//             },
//           }}
//         ></Column>

//         <Column
//           body={templateBody}
//           header={t("actions")}
//           pt={{
//             headerCell: {
//               className: "!p-[0.3rem] !bg-secondary !rounded-tr-md !w-[15%]",
//             },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
//             },
//           }}
//         ></Column>
//       </TreeTable>
//     </div>
//   );
// }

// ---openButNonCollapsable---
// import React, { useEffect, useState } from "react";
// import { TreeTable } from "primereact/treetable";
// import { Column } from "primereact/column";
// import { IoMdEye } from "react-icons/io";
// import { MdOutlineEdit } from "react-icons/md";
// import { MdOutlineDelete } from "react-icons/md";
// import SharedTooltip from "../../components/Shared/SharedTooltip";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";

// export default function AccountsTable({ data }) {
//   const lang = i18next.language;
//   const { t } = useTranslation();
//   const [expandedKeys, setExpandedKeys] = useState({});

//   useEffect(() => {
//     const expandAllNodes = (nodes) => {
//       let expandedKeys = {};
//       const expand = (node) => {
//         if (node.children) {
//           expandedKeys[node.key] = true;
//           node.children.forEach((child) => expand(child));
//         }
//       };
//       nodes.forEach((node) => expand(node));
//       return expandedKeys;
//     };

//     setExpandedKeys(expandAllNodes(data));
//   }, [data]);

//   const templateBody = (rowData) => {
//     return (
//       <div className="flex gap-1">
//         <SharedTooltip content="view" id="my-tooltip-1" />
//         <SharedTooltip id="my-tooltip-2" content="edit" />
//         <SharedTooltip id="my-tooltip-3" content="delete" />
//         <IoMdEye
//           size={25}
//           data-tooltip-id="my-tooltip-1"
//           className="!p-1 !text-black hover:cursor-pointer"
//         />
//         <MdOutlineEdit
//           size={25}
//           data-tooltip-id="my-tooltip-2"
//           className="!p-1 !text-black hover:cursor-pointer"
//         />
//         <MdOutlineDelete
//           size={25}
//           data-tooltip-id="my-tooltip-3"
//           className="!p-1 !text-black hover:cursor-pointer"
//         />
//       </div>
//     );
//   };

//   const balanceBody = (data) => {
//     return (
//       <>
//         {data?.data?.balance ? data?.data?.balance.toFixed(2) : "0"}{" "}
//         {`${t("SAR")}`}
//       </>
//     );
//   };

//   return (
//     <div>
//       <TreeTable
//         value={data}
//         expandedKeys={expandedKeys}
//         onToggle={(e) => setExpandedKeys(e.value)}
//         emptyMessage={
//           <div className="text-center w-full py-4 text-gray-500">
//             {t("No user found")}
//           </div>
//         }
//         pt={{
//           wrapper: { className: "overflow-auto" },
//           table: { className: "min-w-[50rem]" },
//         }}
//       >
//         <Column
//           field={lang === "ar" ? "name_ar" : "name_en"}
//           header={t("name")}
//           expander
//           pt={{
//             headerCell: {
//               className: "!p-[0.3rem] !bg-secondary !rounded-tl-md !w-[40%]",
//             },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[40%]",
//             },
//           }}
//         ></Column>

//         <Column
//           body={balanceBody}
//           field="balance"
//           header={t("balance")}
//           pt={{
//             headerCell: { className: "!p-[0.3rem] !bg-secondary !w-[15%]" },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
//             },
//           }}
//         ></Column>

//         <Column
//           field={lang === "ar" ? "type_ar" : "type_en"}
//           header={t("type")}
//           pt={{
//             headerCell: { className: "!p-[0.3rem] !bg-secondary !w-[15%]" },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
//             },
//           }}
//         ></Column>

//         <Column
//           field={lang === "ar" ? "sub_type_ar" : "sub_type_en"}
//           header={t("subType")}
//           pt={{
//             headerCell: { className: "!p-[0.3rem] !bg-secondary !w-[15%]" },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
//             },
//           }}
//         ></Column>

//         <Column
//           body={templateBody}
//           header={t("actions")}
//           pt={{
//             headerCell: {
//               className: "!p-[0.3rem] !bg-secondary !rounded-tr-md !w-[15%]",
//             },
//             bodyCell: {
//               className:
//                 "!p-[0.25rem] text-[0.875rem] border-b-2 leading-[1.25rem] !border-b-secondary !w-[15%]",
//             },
//           }}
//         ></Column>
//       </TreeTable>
//     </div>
//   );
// }
