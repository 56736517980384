import React, { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCompanyInvoicesList,
  getCreditCompanyInvoicesListApi,
} from "../store/slices/InvoicesSlice";
import CustomPagination from "../components/Shared/CustomPagination";
import Loader from "../Loader";
import { formatDate } from "../Pages/Transactions/TransactionsTable";
import SharedTooltip from "../components/Shared/SharedTooltip";
import { HiOutlinePrinter } from "react-icons/hi2";

function CrdeitCompanyTable() {
  const { creditCompanyInvoiceList, isLoading } = useSelector(
    (state) => state.invoice
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCreditCompanyInvoicesListApi({ page: 1, per_page: 10 }));
  }, [dispatch]);

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getCreditCompanyInvoicesListApi({
        page: newPage,
        per_page: newRowsPerPage,
      })
    );
  };

  const sequenceBodyTemplate = (rowData, options) => options.rowIndex + 1;

  const templateBody = (rowData) => (
    <div className="flex gap-1">
      <SharedTooltip content="print" id="print" />
      <SharedTooltip content="payment" id="payment" />
      <SharedTooltip content="cancel" id="cancel" />

      {/* <SharedTooltip id="edit" content="edit" />
      <SharedTooltip id="delete" content="delete" />
      <SharedTooltip id="print" content="print" /> */}

      <HiOutlinePrinter
        size={25}
        data-tooltip-id="print"
        className="!p-1 !text-black cursor-pointer"
        onClick={() => {
          // handlePrintInvoice(rowData?.id);
        }}
      />

      {/* {rowData.paid !== true && (
        <LiaFileInvoiceDollarSolid
          size={25}
          data-tooltip-id="payment"
          className="!p-1 !text-black hover:cursor-pointer"
          onClick={() => {
            setSelectedEntry(rowData);
            setReceivePaymentDialogVisible(true);
          }}
        />
      )} */}
    </div>
  );

  const statusBody = (data) => {
    return data?.paid ? (
      <div className="bg-green-500 p-1 text-center text-white rounded-md">
        {t("Paid")}
      </div>
    ) : (
      <div className="bg-red-500 p-1 text-center text-white rounded-md">
        {t("Unpaid")}
      </div>
    );
  };
  const balanceBody = (data) => {
    return (
      <>
        {data?.total} {t("SAR")}
      </>
    );
  };
  return (
    <div>
      <div className="flex justify-between items-center mb-3">
        <h2 className="font-semibold text-black/70 text-xl">
          {t("Company Credit Invoices")}{" "}
          <span className="italic text-lg">
            ({creditCompanyInvoiceList?.data?.length || 0}{" "}
            <span className="text-xs font-bold italic">{t("Entries")}</span>)
          </span>
        </h2>
      </div>

      <div>
        <DataTable
          value={creditCompanyInvoiceList?.data}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            body={sequenceBodyTemplate}
            header={t("no")}
            style={{ width: "3rem" }}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="date"
            header={t("date")}
            body={(rowData) => formatDate(rowData.date)}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="invoice_number"
            header={t("invoiceNumber")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="patient.name"
            header={`${t("patient")} ${t("name")}`}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="sub_total_with_tax"
            header={t("amount")}
            body={balanceBody}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="file_number"
            header={t("fileNumber")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary ",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="nationality"
            header={t("nationality")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            body={statusBody}
            field="paid"
            header={t("status")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="description"
            header={t("description")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            body={templateBody}
            header={t("actions")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
        </DataTable>

        {creditCompanyInvoiceList?.data?.length > 0 && (
          <CustomPagination
            currentPage={creditCompanyInvoiceList?.page}
            rowsPerPage={creditCompanyInvoiceList?.per_page}
            totalRecords={creditCompanyInvoiceList?.total_pages}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
}

export default CrdeitCompanyTable;
