import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { Field, Formik } from "formik";
import * as yup from "yup";
import SharedInputText from "../../components/Shared/SharedInputText";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createInvoice,
  getInvoicesList,
  updatePatientInvoice,
} from "../../store/slices/InvoicesSlice";
import SharedDropdown from "../../components/Shared/SharedDropdown";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import { getItems, getPriceListByID } from "../../store/slices/ItemSlice";
import { RiDeleteBin5Line } from "react-icons/ri";
import { getCustomers } from "../../store/slices/CustomerSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrentDateTime } from "../Transactions/CreateTransaction";
import i18next from "i18next";
import CustomSearchInput from "../../components/Shared/CustomSearchInput";
import Loader from "../../Loader";
import { getCashBankAccounts } from "../../store/slices/AcountsSlice";
function CalculateTax(formikProps) {
  const items = Array.isArray(formikProps?.values?.items)
    ? formikProps?.values?.items
    : [];

  const taxableAmount = items
    .filter((itm) => itm?.tax_check)
    .map((itm) => {
      const unitPrice = Number(itm?.unit_price) || 0;
      const quantity = Number(itm?.quantity) || 0;
      return unitPrice * quantity;
    })
    .reduce((acc, curr) => acc + curr, 0);

  const totalDiscount = items
    .filter((itm) => itm?.tax_check)
    .map((itm) => {
      const discountAmount = Number(itm?.discount_amount) || 0;
      const discountType = itm?.discount_type;
      let discount = 0;

      if (discountType === "percentage") {
        discount =
          (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) /
          100;
      } else if (discountType === "fixed") {
        discount = discountAmount;
      }

      return discount;
    })
    .reduce((acc, curr) => acc + curr, 0);

  const taxableAmountAfterDiscount = Math.max(taxableAmount - totalDiscount, 0);

  const taxAmount = (taxableAmountAfterDiscount * 15) / 100;

  return taxAmount;
}

const calculateCashDetails = (formikProps) => {
  const items = Array.isArray(formikProps?.values?.items)
    ? formikProps?.values?.items
    : [];

  const detailedItems = items.map((itm) => {
    const unitPrice = Number(itm.unit_price) || 0;
    const quantity = parseFloat(itm.quantity) || 0;

    const itemAmount = unitPrice * quantity;

    const discountAmount = Number(itm?.discount_amount) || 0;
    const discountType = itm?.discount_type || "";
    let itemDiscount = 0;

    if (discountType === "percentage") {
      itemDiscount = (unitPrice * quantity * discountAmount) / 100;
    } else if (discountType === "fixed") {
      itemDiscount = discountAmount;
    }

    const itemTax = itm.tax_check
      ? CalculateTax({ values: { items: [itm] } })
      : 0;

    const itemNetTotal = itemAmount - itemDiscount + itemTax;

    return {
      discount_amount: Number(discountAmount),
      tax_check: Boolean(itm?.tax_check),
      unitPrice,
      quantity: Number(quantity),
      item_code: itm?.item_code || "",
      item_name: itm?.item_name || "",
      item_type: itm?.item_type || "",
      discount_type: discountType,
      discountAmount: Number(itemDiscount.toFixed(2)),
      discountedAmount: parseFloat((itemAmount - itemDiscount).toFixed(2)),
      itemAmount: parseFloat(itemAmount.toFixed(2)),
      patientShare: parseFloat(itemNetTotal.toFixed(2)),
      companyShare: 0,
      patientTax: parseFloat(itemTax.toFixed(2)),
      companyTax: 0,
    };
  });

  const totalAmount = detailedItems
    .map((itm) => itm.itemAmount)
    .reduce((acc, curr) => acc + curr, 0);

  const totalDiscount = detailedItems
    .map((itm) => itm.discountAmount)
    .reduce((acc, curr) => acc + curr, 0);

  const totalTax = detailedItems
    .map((itm) => itm.patientTax)
    .reduce((acc, curr) => acc + curr, 0);

  const netTotal = detailedItems
    .map((itm) => itm.patientShare)
    .reduce((acc, curr) => acc + curr, 0);

  return {
    itemBreakdown: detailedItems,
    totals: {
      patientShareTotal: parseFloat(totalAmount.toFixed(2)),
      companyShareTotal: 0,
      patientTaxTotal: parseFloat(totalTax.toFixed(2)),
      companyTaxTotal: 0,
      discountedAmount: parseFloat(totalDiscount.toFixed(2)),
      totalAmount:
        parseFloat(totalAmount.toFixed(2)) -
        parseFloat(totalDiscount.toFixed(2)) +
        parseFloat(totalTax.toFixed(2)),
    },
  };
};

export default function CreateInvoicePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = i18next.language;

  useEffect(() => {
    dispatch(getPriceListByID({ payer_id: "0" }));
    dispatch(getCustomers());
    dispatch(getCashBankAccounts());
  }, []);
  const { isLoading } = useSelector((state) => state.invoice);
  const { items } = useSelector((state) => state.item);
  const { user } = useSelector((state) => state.auth);
  const { customers } = useSelector((state) => state.customers);
  const { cashBankAccounts } = useSelector((state) => state?.acount);
  const [validate, setValidate] = useState("");
  const location = useLocation();
  const cancelData = location?.state?.data;

  const typeOptions = [
    { value: "fixed", label: "Fixed" },
    { value: "percent", label: "Percent" },
  ];
  const paid_values = [
    { value: true, label: "Paid" },
    { value: false, label: "Unpaid" },
  ];
  const payment_method_values = [
    { value: "cash", label: lang === "ar" ? "نقدي" : "Cash" },
    { value: "bank", label: lang === "ar" ? "بنك" : "Bank" },
    { value: "mada", label: lang === "ar" ? "مدى" : "MADA" },
  ];
  const vat_values = [
    { value: true, label: lang === "ar" ? "نعم" : "Yes" },
    { value: false, label: lang === "ar" ? "لا" : "No" },
  ];
  const invoice_type_values = [
    { value: "sales", label: "Sales" },
    { value: "credit", label: "Credit" },
  ];

  //   return Object.values(inputData).map((item) => ({
  //     id: item?.ID,
  //     name: lang === "ar" ? item.name_ar : item.name_en,
  //     amount: item?.price,
  //   }));
  // }
  // const ItemsOptions = convertData(items?.data || []);
  // function customer(inputData) {
  //   return Object.values(inputData).map((item) => ({
  //     id: item.id,
  //     name: item.name,
  //     amount: item.name,
  //   }));
  // }

  // const getLabel = (item) => {
  //   const object = ItemsOptions?.find((itm) => Number(itm.id) === item.id);
  //   return object ? object.name : "";
  // };
  // const getAmount = (itemId) => {
  //   const item = items?.data?.find((item) => item.ID === itemId);
  //   return item ? item?.price : 0;
  // };

  function itemsOptions(inputData) {
    return Object.values(inputData || {}).map((item) => ({
      value: item?.ID,
      label: lang === "ar" ? item.name_ar : item.name_en,
      amount: item?.price,
      discount: item?.discount,
      item_code: item?.item_code,
      item_type: item?.item_type,
      item_name: item?.name_ar,
    }));
  }

  function customerOptions(inputData) {
    return Object.values(inputData || {}).map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }

  const ItemsOptions = itemsOptions(items?.data || []);
  const CustomerOptions = customerOptions(customers || []);

  const getLabel = (item) => {
    const cancelDataObject = items.data?.find(
      (itm) => itm.ID === (item?.item_id != null ? item?.item_id : item.id)
    );
    const object = ItemsOptions.find(
      (itm) => itm.value === (item?.item_id != null ? item?.item_id : item.id)
    );

    return cancelData ? cancelDataObject?.name_en : object ? object.label : "";
  };
  console.log(items);

  function calcTotal(total, item) {
    if (item.unit_price != null) {
      return total + item.unit_price * item.quantity;
    }
    return total + item.amount * item.quantity;
  }
  function calcNetTotal(total, item) {
    if (item.unit_price != null) {
      return (
        total +
        item.unit_price * item.quantity -
        item.discount_amount +
        (item.tax_amount * (item.unit_price * item.quantity)) / 100
      );
    }
    return (
      total +
      item.amount * item.quantity -
      item.discount_amount +
      (item.tax_amount * (item.amount * item.quantity)) / 100
    );
  }
  const listAccountsvalues = cashBankAccounts?.data
    ? Object.entries(cashBankAccounts.data).map(([id, account]) => ({
        id: Number(account?.code),
        name: lang === "ar" ? account.name_ar : account.name_en,
      }))
    : [];

  function calTotalVat(total, item) {
    if (item.unit_price != null)
      return (item.tax_amount * (item.unit_price * item.quantity)) / 100;
    return total + (item.tax_amount * (item.amount * item.quantity)) / 100;
  }
  const getAmount = (item) => {
    const cancelDataObject = items.data?.find(
      (itm) => itm.ID === (item?.item_id != null ? item?.item_id : item.id)
    );

    return cancelDataObject?.price != null ? cancelDataObject?.price : 0;
  };

  const initialValues = {
    date: cancelData ? cancelData?.date : getCurrentDateTime(),
    // amount: 0,
    invoice_ref: cancelData ? cancelData?.invoice_number : 0,
    description: cancelData ? cancelData?.description : "",
    paid: cancelData ? cancelData?.paid : false,
    payment_method: cancelData ? cancelData?.payment_method : "",
    customer_id: cancelData ? cancelData?.customer_id : "",
    branch_id: cancelData ? cancelData?.branch_id : user?.branch_id,
    business_id: cancelData ? cancelData?.business_id : user?.business_id,
    invoice_type: "sales",
    items: cancelData ? cancelData?.details : [],
    account_code: cancelData ? cancelData?.account_code : "",
  };
  const validationSchema = yup.object().shape({
    date: yup.string().required(t("required")),
    invoice_type: yup.string().required(t("required")),
    description: yup.string().required(t("required")),
    payment_method: yup.string().required(t("required")),
    customer_id: yup.number().required(t("required")),
    account_code: yup.string().required("Account is required"),
    items: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(t("required")),
          // discount_amount: cancelData
          //   ? null
          //   : yup
          //       .number()
          //       .min(0, `${t("discountAmount ")}${t(" mustBePositive")}`)
          //       .required(t("required")),
          // tax_amount: cancelData
          //   ? null
          //   : yup
          //       .number()
          //       .min(0, `${t("taxAmount")}${t(" mustBePositive")}`)
          //       .required(t("required")),
        })
      )
      .min(1, t("required")),
  });

  const getTotalAmount = (formikProps) => {
    return formikProps?.values?.items.reduce(
      (acc, transaction) =>
        acc + Number(transaction?.quantity) * Number(transaction?.amount),
      0
    );
  };
  const GetTotalDiscount = (formikProps) => {
    const value = formikProps?.values?.items.reduce(
      (acc, transaction) => acc + transaction?.discount_amount,
      0
    );

    return formikProps?.values?.AppoinmentType === "Cash"
      ? getTotalAmount(formikProps) - value
      : getTotalAmount(formikProps) - 100 - value;
  };
  const GetPatientShare = (formikProps, value) => {
    const total = formikProps?.values?.items.reduce(
      (acc, transaction) => acc + transaction?.patientShare,
      0
    );
    const discount = formikProps?.values?.items.reduce(
      (acc, transaction) => acc + transaction?.discount_amount,
      0
    );
    if (value === "ps") {
      return total;
    } else if (value === "ins")
      return getTotalAmount(formikProps) - total - discount;
  };
  const GetNetTotal = (formikProps) => {
    return (
      GetTotalDiscount(formikProps) + (GetTotalDiscount(formikProps) * 15) / 100
    );
  };
  const formikRef = useRef(null);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={async (data, { resetForm }) => {
          if (cancelData) {
            dispatch(
              updatePatientInvoice({
                branch_id: data?.branch_id,
                business_id: data?.business_id,
                customer_id: data?.customer_id,
                date: data?.date,
                invoice_ref: data?.invoice_ref,
                description: data?.description,
                invoice_type: data?.invoice_type,
                ...(formikRef?.current?.values?.paid === true && {
                  account_code: data?.account_code,
                }),
                item_details: calculateCashDetails(formikRef?.current),
                paid: data?.paid,
                payment_method: data?.payment_method,
              })
            ).then(() => {
              resetForm();
              navigate("/manager/creditInvoiceTable");
              dispatch(getInvoicesList({ page: 1, per_page: 10 }));
            });
          } else {
            const resultAction = await dispatch(
              createInvoice({
                branch_id: data?.branch_id,
                business_id: data?.business_id,
                customer_id: data?.customer_id,
                date: data?.date,
                description: data?.description,
                invoice_type: data?.invoice_type,
                ...(formikRef?.current?.values?.paid === true && {
                  account_code: data?.account_code,
                }),
                item_details: calculateCashDetails(formikRef?.current),
                paid: data?.paid,
                payment_method: data?.payment_method,
              })
            );
            if (createInvoice.fulfilled.match(resultAction)) {
              resetForm();
              navigate("/manager/invoices");
              // dispatch(getInvoicesList({ page: 1, per_page: 10 }));
            }
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validate={(values) => {
          validationSchema
            .validate(values, { abortEarly: false })
            .then(() => console.log("Validation passed"))
            .catch((err) => console.log("Validation errors:", err.errors));
        }}
      >
        {(props) => (
          <>
            <div className="flex rounded-md bg-gray-100 p-6 w-full gap-4">
              <div
                className={` ${
                  props.values.items.length > 0 ? "w-[70%]" : "w-full"
                }`}
              >
                <h2 className="text-3xl font-bold mb-6 text-gray-800">
                  {`${t(cancelData ? "Update" : "create")} ${t(
                    "salesinvoice"
                  )}`}
                  {/* {console.log(props?.values)} */}
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full mb-3">
                  <SharedInputText
                    type={"datetime-local"}
                    label={t("date")}
                    name={"date"}
                    placeholder="Enter Date"
                    className="!p-1.5"
                  />
                  {console.log(props?.errors)}
                  <CustomSearchInput
                    name="customer_id"
                    placeholder={`${t("select")} ${t("customers")}`}
                    // options={customer(customers || [])}
                    options={CustomerOptions}
                    label={t("customers")}
                    className={"!w-full"}
                  />
                  <SharedDropdown
                    label={t("payment_method")}
                    name={"payment_method"}
                    options={payment_method_values}
                  />
                  <SharedDropdown
                    label={t("paid")}
                    name={"paid"}
                    options={paid_values}
                  />
                  {props?.values?.paid === true && (
                    <CustomSearchDropdown
                      label={t("account")}
                      className="!w-full"
                      name={"account_code"}
                      options={listAccountsvalues}
                      placeholder={`${t("select")} ${t("account")}`}
                    />
                  )}
                  <SharedInputText
                    label={t("description")}
                    name={"description"}
                    placeholder={`${t("enter")} ${t("description")}`}
                    className="!p-1.5"
                  />
                </div>
                <div className="max-h-[270px] overflow-y-auto scrollbar">
                  {props?.values?.items?.map((item, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-1 gap-2 mb-4 border-t border-b py-4"
                    >
                      <div className="flex gap-4 items-center">
                        <div className="flex">
                          <span className="font-semibold">{t("name")}: </span>
                          <div className="px-2">{item?.item_name}</div>
                        </div>
                        <div className="flex items-center">
                          <span className="font-semibold">
                            {t("ItemAmount")}:{" "}
                          </span>
                          <div className="px-2">
                            {/* {getAmount(item)} */}
                            <Field
                              name={`items[${index}].unit_price`}
                              as={SharedInputText}
                              type="number"
                              min={1}
                              placeholder={`${t("enter")}${t("amount")}`}
                              className="!p-0.5"
                              value={
                                item.amount ? item?.amount : item?.unit_price
                              }
                              onChange={(e) => {
                                const newItems = [...props.values.items];
                                newItems[index].unit_price = Number(
                                  e.target.value
                                );
                                props.setFieldValue("items", newItems);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-5 gap-4">
                        <Field
                          name={`items[${index}].quantity`}
                          as={SharedInputText}
                          label={t(`quantity`)}
                          type="number"
                          placeholder={`${t("enter")}${t("quantity")}`}
                          min={1}
                          className="!p-1.5"
                          value={item.quantity === 0 ? "" : item.quantity}
                          onChange={(e) => {
                            const newValue =
                              e.target.value === ""
                                ? ""
                                : Number(e.target.value);
                            const newItems = [...props.values.items];
                            newItems[index].quantity = newValue;
                            props.setFieldValue("items", newItems);
                          }}
                        />
                        <div className="flex flex-col justify-between">
                          <label className="text-base font-medium">
                            Discount Type
                          </label>
                          <div className="flex w-full">
                            <button
                              type="button"
                              onClick={() => {
                                props.setFieldValue(
                                  `items[${index}].discount_type`,
                                  "percentage"
                                );
                              }}
                              className={`w-[50%] border !p-1.5 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                props.values.items[index].discount_type ===
                                "percentage"
                                  ? "!bg-primary !border-primary text-white"
                                  : "!bg-white !border-white text-primary"
                              }`}
                            >
                              Percent
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                props.setFieldValue(
                                  `items[${index}].discount_type`,
                                  "fixed"
                                );
                              }}
                              className={`w-[50%] border !p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                props.values.items[index].discount_type ===
                                "fixed"
                                  ? "!bg-primary !border-primary text-white"
                                  : "!bg-white !border-white text-primary"
                              }`}
                            >
                              Fix
                            </button>
                          </div>
                        </div>
                        {/* <Field
                          name={`items[${index}].discount_type`}
                          as={SharedDropdown}
                          label={t(`discountType`)}
                          options={[
                            { value: "fixed", label: "Fixed" },
                            { value: "percentage", label: "Percentage" },
                          ]}
                        /> */}
                        <Field
                          name={`items[${index}].discount_amount`}
                          as={SharedInputText}
                          label={t("discountAmount")}
                          type="number"
                          placeholder={`${t("enter")}${t("amount")}`}
                          className="!p-1.5"
                          min={0}
                          value={
                            item.discount_amount === 0
                              ? 0
                              : item.discount_amount
                          }
                          onChange={(e) => {
                            const newValue =
                              e.target.value === 0 ? 0 : Number(e.target.value);
                            const newItems = [...props.values.items];
                            newItems[index].discount_amount = newValue;
                            props.setFieldValue("items", newItems);
                          }}
                        />
                        <div className="flex flex-col justify-between">
                          <label className="text-base font-medium">Tax %</label>
                          <div className="flex w-full">
                            <button
                              type="button"
                              onClick={() => {
                                props.setFieldValue(
                                  `items[${index}].tax_check`,
                                  false
                                );
                              }}
                              className={`w-[50%] border !p-1.5 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                props.values.items[index].tax_check === false
                                  ? "!bg-primary !border-primary text-white"
                                  : "!bg-white !border-white text-primary"
                              }`}
                            >
                              False
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                props.setFieldValue(
                                  `items[${index}].tax_check`,
                                  true
                                );
                              }}
                              className={`w-[50%] border !p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                props.values.items[index].tax_check === true
                                  ? "!bg-primary !border-primary text-white"
                                  : "!bg-white !border-white text-primary"
                              }`}
                            >
                              True
                            </button>
                          </div>
                        </div>
                        {/* <Field
                          name={`items[${index}].tax_check`}
                          as={SharedDropdown}
                          label={`${t("vat")} ${t("amount")}`}
                          type="number"
                          placeholder={`${t("select")} ${t("vat")}`}
                          options={vat_values}
                          value={item.tax_check}
                          onChange={(e) => {
                            const newItems = [...props.values.items];
                            newItems[index].tax_check = Number(e.target.value);
                            props.setFieldValue("items", newItems);
                          }}
                        /> */}

                        <div className="flex justify-end items-end">
                          <span className="h-[30px] w-full flex justify-end pr-5">
                            <RiDeleteBin5Line
                              size={20}
                              className="cursor-pointer text-red-500"
                              onClick={() => {
                                const newItems = props.values.items.filter(
                                  (_, i) => i !== index
                                );
                                props.setFieldValue("items", newItems);
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      {props.errors.items && props.errors.items[index] && (
                        <div className="col-span-4 text-red-500">
                          {Object.values(props.errors.items[index]).map(
                            (error, i) => (
                              <div key={i}>{error}</div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  {props.errors.items &&
                    typeof props.errors.items === "string" && (
                      <div className="text-red-500">{props.errors.items}</div>
                    )}
                  {/* {console.log(getTotalAmount(props))} */}
                </div>

                <div className="mt-4 ">
                  <CustomSearchInput
                    label={t("items")}
                    name={"items"}
                    options={ItemsOptions}
                    placeholder={`${t("select")} ${t("items")}`}
                    className="!w-full"
                    // onChange={(e) => {
                    //   const newItem = {
                    //     id: Number(e.target.value),
                    //     patientShare: 0,
                    //     amount: getAmount(Number(e.target.value)),
                    //     quantity: 1,
                    //     discount_type: "fixed",
                    //     discount_amount: 0,
                    //     tax_type: "percent",
                    //     tax_amount: 15,
                    //   };
                    //   props.setFieldValue("items", [
                    //     ...props.values.items,
                    //     newItem,
                    //   ]);
                    // }}
                    onChange={(selectedOption) => {
                      const newItem = {
                        id: selectedOption.value,
                        patientShare: 0,
                        unit_price: Number(selectedOption.amount),
                        quantity: 1,
                        discount_type: "percentage",
                        discount_amount: Number(selectedOption?.discount) || 5,
                        tax_type: "percent",
                        tax_amount: 15,
                        tax_check: false,
                        item_code: selectedOption?.item_code,
                        item_type: selectedOption?.item_type,
                        item_name: selectedOption?.label,
                      };
                      props.setFieldValue("items", [
                        ...props.values.items,
                        newItem,
                      ]);
                    }}
                  />
                </div>

                <div className="flex justify-between mt-6">
                  <div>
                    <SharedButton
                      icon="pi pi-plus"
                      label={t("CREATECUSTOMERS")}
                      className="!p-1.5 bg-gradient"
                      onClick={() => navigate("/manager/customers")}
                    />
                  </div>

                  <div className="flex gap-6">
                    <SharedButton
                      icon="pi pi-times"
                      label={t("Cancel")}
                      className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                      onClick={() => {
                        navigate("/manager/invoices");
                        props.resetForm();
                      }}
                    />
                    <SharedButton
                      icon="pi pi-check"
                      label={t(cancelData ? "Update" : "Submit")}
                      className="!p-1.5 bg-gradient"
                      disabled={isLoading}
                      onClick={props.handleSubmit}
                    />
                  </div>
                </div>
              </div>

              <div
                className={`${
                  props.values.items.length > 0 ? "w-[30%]" : "hidden"
                }`}
              >
                {props?.values?.items?.length > 0 && (
                  <div className="mt-6 bg-white rounded-lg shadow-md p-6">
                    <h3 className="text-2xl font-semibold mb-4 text-gray-800">
                      {t("BillSummary")}
                    </h3>
                    <div className="space-y-4">
                      <div className="bg-gray-50 p-4 !pb-2 rounded-lg">
                        <h4 className="text-lg font-semibold mb-4 text-gray-700 text-center">
                          {t("YourTotalBill")}
                        </h4>
                        <div className="space-y-2">
                          {/* Total Amount */}
                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("amount")}`}:
                            </span>
                            <span className="font-semibold">
                              {
                                calculateCashDetails(props)?.totals
                                  ?.patientShareTotal
                              }
                            </span>
                          </div>

                          {/* Total Discount */}
                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("discount")}`}:
                            </span>
                            <span className="font-semibold">
                              {
                                calculateCashDetails(props)?.totals
                                  ?.discountedAmount
                              }
                            </span>
                          </div>

                          {/* Total VAT */}
                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("vat")}`}:
                            </span>
                            <span className="font-semibold">
                              {
                                calculateCashDetails(props)?.totals
                                  ?.patientTaxTotal
                              }
                            </span>
                          </div>

                          {/* Final Total (Net Amount) */}
                          <div className="flex justify-between text-lg font-semibold mt-4">
                            <span className="text-gray-600">{t("total")}:</span>
                            <span>
                              {calculateCashDetails(props)?.totals?.totalAmount}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Formik>
      <Loader showLoader={isLoading} />
    </>
  );
}

// import { Dialog } from "primereact/dialog";
// import React, { useEffect, useState } from "react";
// import { Field, Formik } from "formik";
// import * as yup from "yup";
// import SharedInputText from "../../components/Shared/SharedInputText";
// import SharedButton from "../../components/Shared/SharedButton";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   createInvoice,
//   getInvoicesList,
// } from "../../store/slices/InvoicesSlice";
// import SharedDropdown from "../../components/Shared/SharedDropdown";
// import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
// import { getItems, getPriceListByID } from "../../store/slices/ItemSlice";
// import { RiDeleteBin5Line } from "react-icons/ri";
// import { getCustomers } from "../../store/slices/CustomerSlice";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { getCurrentDateTime } from "../Transactions/CreateTransaction";
// import i18next from "i18next";

// export default function CreateInvoicePage() {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const lang = i18next.language;

//   useEffect(() => {
//     dispatch(getPriceListByID({ payer_id: "0" }));
//     dispatch(getCustomers());
//   }, []);

//   const { items } = useSelector((state) => state.item);
//   const { user } = useSelector((state) => state.auth);
//   const { customers } = useSelector((state) => state.customers);
//   const [validate, setValidate] = useState("");

//   const typeOptions = [
//     { value: "fixed", label: "Fixed" },
//     { value: "percent", label: "Percent" },
//   ];
//   const paid_values = [
//     { value: true, label: "Paid" },
//     { value: false, label: "Unpaid" },
//   ];
//   const payment_method_values = [
//     { value: "cash", label: lang === "ar" ? "نقدي" : "Cash" },
//     { value: "bank", label: lang === "ar" ? "بنك" : "Bank" },
//     { value: "mada", label: lang === "ar" ? "مدى" : "MADA" },

//     // { value: "cash", label: "Cash" },
//     // { value: "bank", label: "Bank" },
//     // { value: "mada", label: "MADA" },
//   ];
//   const vat_values = [
//     { value: 15, label: lang === "ar" ? "نعم" : "Yes" },
//     { value: 0, label: lang === "ar" ? "لا" : "No" },
//   ];
//   function convertData(inputData) {
//     return Object.values(inputData).map((item) => ({
//       id: item?.ID,
//       name: lang === "ar" ? item.name_ar : item.name_en,
//       amount: item?.price,
//     }));
//   }
//   const ItemsOptions = convertData(items?.data || []);
//   function customer(inputData) {
//     return Object.values(inputData).map((item) => ({
//       id: item.id,
//       name: item.name,
//       amount: item.name,
//     }));
//   }

//   const invoice_type_values = [
//     { value: "sales", label: "Sales" },
//     { value: "credit", label: "Credit" },
//   ];

//   const initialValues = {
//     date: getCurrentDateTime(),
//     // amount: 0,
//     description: "",
//     paid: false,
//     payment_method: "",
//     customer_id: "",
//     branch_id: user?.branch_id,
//     business_id: user?.business_id,
//     invoice_type: "sales",
//     items: [],
//   };
//   const validationSchema = yup.object().shape({
//     date: yup.string().required(t("required")),
//     invoice_type: yup.string().required(t("required")),
//     // amount: yup
//     //   .number()
//     //   .required("Amount is required")
//     //   .min(0, "Amount must be non-negative"),
//     description: yup.string().required(t("required")),
//     // paid: yup.boolean().required(t("required")),
//     payment_method: yup.string().required(t("required")),
//     customer_id: yup.number().required(t("required")),
//     items: yup
//       .array()
//       .of(
//         yup.object().shape({
//           id: yup.number().required(t("required")),
//           //   patientShare: yup
//           //     .number()
//           //     .min(0, "Patient share must be non-negative")
//           //     .required(t("required")),
//           // quantity: yup
//           //   .number()
//           //   .integer()
//           //   .min(1, "Quantity must be at least 1")
//           //   .required(t("required")),

//           // discount_type: yup
//           //   .string()
//           //   .oneOf(["fixed", "percent"], "Invalid discount type")
//           //   .required(t("required")),
//           discount_amount: yup
//             .number()
//             .min(0, `${t("discountAmount ")}${t(" mustBePositive")}`)
//             .required(t("required")),
//           // tax_type: yup
//           //   .string()
//           //   .oneOf(["fixed", "percent"], "Invalid tax type")
//           //   .required(t("required")),
//           tax_amount: yup
//             .number()
//             .min(0, `${t("taxAmount")}${t(" mustBePositive")}`)
//             .required(t("required")),
//         })
//       )
//       .min(1, t("required")),
//   });
//   const getLabel = (item) => {
//     const object = ItemsOptions?.find((itm) => Number(itm.id) === item.id);
//     return object ? object.name : "";
//   };
//   const getAmount = (itemId) => {
//     const item = items?.data?.find((item) => item.ID === itemId);
//     return item ? item?.price : 0;
//   };

//   const getTotalAmount = (formikProps) => {
//     return formikProps?.values?.items.reduce(
//       (acc, transaction) =>
//         acc + Number(transaction?.quantity) * Number(transaction?.amount),
//       0
//     );
//   };
//   const GetTotalDiscount = (formikProps) => {
//     const value = formikProps?.values?.items.reduce(
//       (acc, transaction) => acc + transaction?.discount_amount,
//       0
//     );

//     return formikProps?.values?.AppoinmentType === "Cash"
//       ? getTotalAmount(formikProps) - value
//       : getTotalAmount(formikProps) - 100 - value;
//   };
//   const GetPatientShare = (formikProps, value) => {
//     const total = formikProps?.values?.items.reduce(
//       (acc, transaction) => acc + transaction?.patientShare,
//       0
//     );
//     const discount = formikProps?.values?.items.reduce(
//       (acc, transaction) => acc + transaction?.discount_amount,
//       0
//     );
//     if (value === "ps") {
//       return total;
//     } else if (value === "ins")
//       return getTotalAmount(formikProps) - total - discount;
//   };
//   const GetNetTotal = (formikProps) => {
//     return (
//       GetTotalDiscount(formikProps) + (GetTotalDiscount(formikProps) * 15) / 100
//     );
//   };

//   return (
//     <>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         onSubmit={async (data, { resetForm }) => {
//           const resultAction = await dispatch(createInvoice(data));
//           if (createInvoice.fulfilled.match(resultAction)) {
//             resetForm();
//             navigate("/manager/invoices");
//             // dispatch(getInvoicesList({ page: 1, per_page: 10 }));
//           }
//         }}
//         validateOnChange={true}
//         validateOnBlur={true}
//         validate={(values) => {
//           validationSchema
//             .validate(values, { abortEarly: false })
//             .then(() => console.log("Validation passed"))
//             .catch((err) => console.log("Validation errors:", err.errors));
//         }}
//       >
//         {(props) => (
//           <>
//             <div className="flex rounded-md bg-gray-100 p-6 w-full gap-4">
//               <div
//                 className={` ${
//                   props.values.items.length > 0 ? "w-[70%]" : "w-full"
//                 }`}
//               >
//                 <h2 className="text-3xl font-bold mb-6 text-gray-800">
//                   {`${t("create")} ${t("salesinvoice")}`}
//                   {/* {console.log(props?.values)} */}
//                 </h2>

//                 <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full mb-3">
//                   <SharedInputText
//                     type={"datetime-local"}
//                     label={t("date")}
//                     name={"date"}
//                     placeholder="Enter Date"
//                     className="!p-1.5"
//                   />
//                   {/* <SharedInputText
//                 type={"number"}
//                 label={"Amount"}
//                 name={"amount"}
//                 placeholder="Enter Amount"
//                 className="!p-1.5"
//               /> */}
//                   <CustomSearchDropdown
//                     name="customer_id"
//                     placeholder={`${t("select")} ${t("customers")}`}
//                     options={customer(customers || [])}
//                     label={t("customers")}
//                     className={"!w-full"}
//                   />

//                   <SharedDropdown
//                     label={t("payment_method")}
//                     name={"payment_method"}
//                     options={payment_method_values}
//                   />

//                   {/* <SharedDropdown
//                     label={t("paid")}
//                     name={"paid"}
//                     options={paid_values}
//                   /> */}
//                   {/* <SharedDropdown
//                 label={"Invoice Type"}
//                 name={"invoice_type"}
//                 options={invoice_type_values}
//               /> */}

//                   <SharedInputText
//                     label={t("description")}
//                     name={"description"}
//                     placeholder={`${t("enter")} ${t("description")}`}
//                     className="!p-1.5"
//                   />
//                 </div>

//                 <div className="max-h-[270px] overflow-y-auto scrollbar">
//                   {props?.values?.items?.map((item, index) => (
//                     <div
//                       key={index}
//                       className="grid grid-cols-1 gap-2 mb-4 border-t border-b py-4"
//                     >
//                       <div className="flex gap-4">
//                         <div className="flex">
//                           <span className="font-semibold">{t("name")}: </span>
//                           <div className="px-2">{getLabel(item)}</div>
//                         </div>
//                         <div className="flex">
//                           <span className="font-semibold">
//                             {t("ItemAmount")}:{" "}
//                           </span>
//                           <div className="px-2">{getAmount(item.id)}</div>
//                         </div>
//                       </div>

//                       <div className="grid grid-cols-5 gap-4">
//                         {/* <Field
//                           name={`items[${index}].patientShare`}
//                           as={SharedInputText}
//                           label={t("patientShare")}
//                           type="number"
//                           placeholder={`${t("enter")}${t("patientShare")}`}
//                           className="!p-1.5"
//                           value={item.patientShare}
//                           onChange={(e) => {
//                             const newItems = [...props.values.items];
//                             newItems[index].patientShare = Number(
//                               e.target.value
//                             );
//                             props.setFieldValue("items", newItems);
//                           }}
//                         /> */}
//                         <Field
//                           name={`items[${index}].quantity`}
//                           as={SharedInputText}
//                           label={t(`quantity`)}
//                           type="number"
//                           min={1}
//                           placeholder={`${t("enter")}${t("quantity")}`}
//                           className="!p-1.5"
//                           value={item.quantity}
//                           onChange={(e) => {
//                             const newItems = [...props.values.items];
//                             newItems[index].quantity = Number(e.target.value);
//                             props.setFieldValue("items", newItems);
//                           }}
//                         />
//                         <Field
//                           name={`items[${index}].discount_amount`}
//                           as={SharedInputText}
//                           label={t("discountAmount")}
//                           type="number"
//                           placeholder={`${t("enter")}${t("amount")}`}
//                           className="!p-1.5"
//                           value={item.discount_amount}
//                           onChange={(e) => {
//                             const newItems = [...props.values.items];
//                             newItems[index].discount_amount = Number(
//                               e.target.value
//                             );
//                             props.setFieldValue("items", newItems);
//                           }}
//                         />
//                         <Field
//                           name={`items[${index}].tax_amount`}
//                           as={SharedDropdown}
//                           label={`${t("vat")} ${t("amount")}`}
//                           type="number"
//                           placeholder={`${t("select")} ${t("vat")}`}
//                           options={vat_values}
//                           value={item.tax_amount}
//                           onChange={(e) => {
//                             const newItems = [...props.values.items];
//                             newItems[index].tax_amount = Number(e.target.value);
//                             props.setFieldValue("items", newItems);
//                           }}
//                           min={0}
//                         />

//                         <div className="flex justify-end items-end">
//                           <span className="h-[30px] w-full flex justify-end pr-5">
//                             <RiDeleteBin5Line
//                               size={20}
//                               className="cursor-pointer text-red-500"
//                               onClick={() => {
//                                 const newItems = props.values.items.filter(
//                                   (_, i) => i !== index
//                                 );
//                                 props.setFieldValue("items", newItems);
//                               }}
//                             />
//                           </span>
//                         </div>
//                       </div>

//                       {/* <SharedDropdown
//                     label={t("discountType")}
//                     name={`items[${index}].discount_type`}
//                     options={typeOptions}
//                     value={item.discount_type}
//                     onChange={(e) => {
//                       const newItems = [...props.values.items];
//                       newItems[index].discount_type = e.target.value;
//                       props.setFieldValue("items", newItems);
//                     }}
//                   /> */}

//                       {/* <SharedDropdown
//                     label={t("taxType")}
//                     name={`items[${index}].tax_type`}
//                     options={typeOptions}
//                     value={item.tax_type}
//                     onChange={(e) => {
//                       const newItems = [...props.values.items];
//                       newItems[index].tax_type = e.target.value;
//                       props.setFieldValue("items", newItems);
//                     }}
//                   /> */}

//                       {props.errors.items && props.errors.items[index] && (
//                         <div className="col-span-4 text-red-500">
//                           {Object.values(props.errors.items[index]).map(
//                             (error, i) => (
//                               <div key={i}>{error}</div>
//                               // <div key={i}>
//                               //   {typeof error === "string"
//                               //     ? error
//                               //     : JSON.stringify(error)}
//                               // </div>
//                             )
//                           )}
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                   {props.errors.items &&
//                     typeof props.errors.items === "string" && (
//                       <div className="text-red-500">{props.errors.items}</div>
//                     )}
//                   {/* {console.log(getTotalAmount(props))} */}
//                 </div>

//                 <div className="mt-4 ">
//                   <CustomSearchDropdown
//                     label={t("items")}
//                     name={"items"}
//                     options={ItemsOptions}
//                     placeholder={`${t("select")} ${t("items")}`}
//                     className="!w-full"
//                     onChange={(e) => {
//                       const newItem = {
//                         id: Number(e.target.value),
//                         patientShare: 0,
//                         amount: getAmount(Number(e.target.value)),
//                         quantity: 1,
//                         discount_type: "fixed",
//                         discount_amount: 0,
//                         tax_type: "percent",
//                         tax_amount: 15,
//                       };
//                       props.setFieldValue("items", [
//                         ...props.values.items,
//                         newItem,
//                       ]);
//                     }}
//                   />
//                 </div>

//                 <div className="flex justify-between mt-6">
//                   <div>
//                     <SharedButton
//                       icon="pi pi-plus"
//                       label={t("CREATECUSTOMERS")}
//                       className="!p-1.5 bg-gradient"
//                       onClick={() => navigate("/manager/customers")}
//                     />
//                   </div>

//                   <div className="flex gap-6">
//                     <SharedButton
//                       icon="pi pi-times"
//                       label={t("Cancel")}
//                       className="!p-1.5 !bg-red-400 hover:!bg-red-400"
//                       onClick={() => {
//                         //   setCreateDialogVisible(false);
//                         navigate("/manager/invoices");
//                         props.resetForm();
//                       }}
//                     />
//                     <SharedButton
//                       icon="pi pi-check"
//                       label={t("Submit")}
//                       className="!p-1.5 bg-gradient"
//                       onClick={props.handleSubmit}
//                     />
//                   </div>
//                 </div>
//               </div>

//               {/* <div
//                 className={`${
//                   props.values.items.length > 0 ? "w-[30%]" : "hidden"
//                 }`}
//               >
//                 {props?.values?.items?.length > 0 && (
//                   <div className="mt-6 bg-white rounded-lg shadow-md p-6">
//                     <h3 className="text-2xl font-semibold mb-4 text-gray-800">
//                       Bill Summary
//                     </h3>
//                     <div className="space-y-4">
//                       {props?.values?.items.map((item, index) => (
//                         <div key={index} className="bg-gray-50 p-4 rounded-lg">
//                           <h4 className="text-lg font-semibold mb-2 text-gray-700">
//                             {getLabel(item)}
//                             Your Total Bill
//                           </h4>
//                           <div className="space-y-2">
//                             <div className="flex justify-between">
//                               <span className="text-gray-600">
//                                 Amount:
//                               </span>
//                               <span className="font-semibold">
//                                 {item.amount}
//                               </span>
//                             </div>

//                             <div className="flex justify-between">
//                               <span className="text-gray-600">Quantity:</span>
//                               <span className="font-semibold">
//                                 {item.quantity}
//                               </span>
//                             </div>

//                             <div className="flex justify-between">
//                               <span className="text-gray-600">
//                                 Patient Share:
//                               </span>
//                               <span className="font-semibold">
//                                 {item.patientShare}
//                               </span>
//                             </div>

//                             <div className="flex justify-between">
//                               <span className="text-gray-600">
//                                 Discount Amount:
//                               </span>
//                               <span className="font-semibold">
//                                 {item.discount_amount}
//                               </span>
//                             </div>
//                             <div className="flex justify-between">
//                               <span className="text-gray-600">Tax Amount:</span>
//                               <span className="font-semibold">
//                                 {item.tax_amount}
//                               </span>
//                             </div>
//                           </div>
//                         </div>
//                       ))}

//                       <div className="flex justify-between text-lg font-semibold mt-4">
//                         <span className="text-gray-600">Net Total:</span>
//                         <span>
//                           {props.values.items
//                             .reduce(
//                               (total, item) =>
//                                 total +
//                                 item.amount * item.quantity -
//                                 item.discount_amount +
//                                 item.tax_amount,
//                               0
//                             )
//                             .toFixed(2)}
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div> */}

//               <div
//                 className={`${
//                   props.values.items.length > 0 ? "w-[30%]" : "hidden"
//                 }`}
//               >
//                 {props?.values?.items?.length > 0 && (
//                   <div className="mt-6 bg-white rounded-lg shadow-md p-6">
//                     <h3 className="text-2xl font-semibold mb-4 text-gray-800">
//                       {t("BillSummary")}
//                     </h3>
//                     <div className="space-y-4">
//                       <div className="bg-gray-50 p-4 !pb-2 rounded-lg">
//                         <h4 className="text-lg font-semibold mb-4 text-gray-700 text-center">
//                           {t("YourTotalBill")}
//                         </h4>
//                         <div className="space-y-2">
//                           <div className="flex justify-between">
//                             <span className="text-gray-600">
//                               {`${t("Total")} ${t("amount")}`}:
//                             </span>
//                             <span className="font-semibold">
//                               {props.values.items
//                                 .reduce(
//                                   (total, item) =>
//                                     total + item.amount * item.quantity,
//                                   0
//                                 )
//                                 .toFixed(2)}
//                             </span>
//                           </div>

//                           <div className="flex justify-between">
//                             <span className="text-gray-600">
//                               {`${t("Total")} ${t("discount")}`}:
//                             </span>
//                             <span className="font-semibold">
//                               {props.values.items
//                                 .reduce(
//                                   (total, item) => total + item.discount_amount,
//                                   0
//                                 )
//                                 .toFixed(2)}
//                             </span>
//                           </div>

//                           <div className="flex justify-between">
//                             <span className="text-gray-600">
//                               {`${t("Total")} ${t("vat")}`}:
//                             </span>
//                             <span className="font-semibold">
//                               {props.values.items
//                                 .reduce(
//                                   (total, item) => total + item.tax_amount,
//                                   0
//                                 )
//                                 .toFixed(2)}
//                             </span>
//                           </div>

//                           <div className="flex justify-between text-lg font-semibold mt-4">
//                             <span className="text-gray-600">{t("total")}:</span>
//                             <span>
//                               {props.values.items
//                                 .reduce(
//                                   (total, item) =>
//                                     total +
//                                     item.amount * item.quantity -
//                                     item.discount_amount +
//                                     item.tax_amount,
//                                   0
//                                 )
//                                 .toFixed(2)}
//                             </span>
//                           </div>
//                         </div>
//                       </div>

//                       {/* <div className="flex justify-between text-lg font-semibold mt-4">
//                         <span className="text-gray-600">Net Total:</span>
//                         <span>
//                           {props.values.items
//                             .reduce(
//                               (total, item) =>
//                                 total +
//                                 item.amount * item.quantity -
//                                 item.discount_amount +
//                                 item.tax_amount,
//                               0
//                             )
//                             .toFixed(2)}
//                         </span>
//                       </div> */}
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </>
//         )}
//       </Formik>
//     </>
//   );
// }
