import { Dialog } from "primereact/dialog";
import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createNewBranch,
  editBranch,
  getBranchList,
} from "../../store/slices/BusinessSlice";
import SharedButton from "../../components/Shared/SharedButton";
import Loader from "../../Loader";
import SharedInputText from "../../components/Shared/SharedInputText";

export default function CreateBranch({
  setCreateDialogVisible,
  createDialogVisible,
  dispatch,
  editData = null,
}) {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.business);

  const initialValues = {
    name_en: editData?.name_en || "",
    name_ar: editData?.name_ar || "",
    address: editData?.address || "",
    contact_info: editData?.contact_info || "",
    business_id: editData?.business_id || user?.business_id,
  };

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required(t("required")),
    name_ar: Yup.string().required(t("required")),
    address: Yup.string().required(t("required")),
    contact_info: Yup.string().required(t("required")),
  });

  return (
    <>
      <Dialog
        header={t("CreateBranch")}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            if (editData) {
              const resultAction = await dispatch(
                editBranch({ data: values, id: editData?.id })
              );
              if (editBranch.fulfilled.match(resultAction)) {
                setCreateDialogVisible(false);
                resetForm();
                dispatch(getBranchList({ page: 1, per_page: 10 }));
              }
            } else {
              const resultAction = await dispatch(createNewBranch(values));
              if (createNewBranch.fulfilled.match(resultAction)) {
                setCreateDialogVisible(false);
                resetForm();
                dispatch(getBranchList({ page: 1, per_page: 10 }));
              }
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-3 gap-4 w-full">
                <Field
                  as={SharedInputText}
                  label={t("name_en")}
                  name="name_en"
                  placeholder={`${t("enter")} ${t("name_en")}`}
                  className="!p-1.5"
                />
                <Field
                  as={SharedInputText}
                  label={t("name_ar")}
                  name="name_ar"
                  placeholder={`${t("enter")} ${t("name_ar")}`}
                  className="!p-1.5"
                />

                <Field
                  as={SharedInputText}
                  label={t("contact_info")}
                  name="contact_info"
                  placeholder={t("contact_info_placeholder")}
                  className="!p-1.5"
                />

                <Field
                  as={SharedInputText}
                  label={t("address")}
                  name="address"
                  placeholder={t("address_placeholder")}
                  className="!p-1.5"
                />
              </div>

              <div className="flex justify-end gap-6 mt-6">
                <SharedButton
                  icon="pi pi-times"
                  label={t("Cancel")}
                  className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                  onClick={() => {
                    setCreateDialogVisible(false);
                    formik.resetForm();
                  }}
                  type="button"
                />
                <SharedButton
                  icon="pi pi-check"
                  disabled={isLoading}
                  label={editBranch ? t("Update") : "Submit"}
                  className="!p-1.5 bg-gradient"
                  type="submit"
                />
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
      <Loader showLoader={isLoading} />
    </>
  );
}
