import React, { useEffect, useState } from "react";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import { useTranslation } from "react-i18next";
import SharedButton from "../../components/Shared/SharedButton";
import { useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../Transactions/TransactionsTable";
import {
  deletePaymentVoucher,
  getPaymentVoucher,
} from "../../store/slices/VoucherSlice";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { getCostCenter } from "../../store/slices/CostCenterSlice";
import i18next from "i18next";
import SharedConfirmDialog from "../../components/Shared/SharedConfirmDialog";
import Loader from "../../Loader";
import CustomPagination from "../../components/Shared/CustomPagination";

function PaymentVoucher() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteVoucher, setDeleteVoucher] = useState(false);
  const [delId, setDelId] = useState(null);
  const { paymentVouchers, isLoading } = useSelector((state) => state.voucher);
  const { costCenters } = useSelector((state) => state?.costCenter);
  const lang = i18next.language;
  useEffect(() => {
    dispatch(getCostCenter());
    dispatch(getPaymentVoucher({ page: 1, per_page: 10 }));
  }, []);

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(getPaymentVoucher({ page: newPage, per_page: newRowsPerPage }));
  };
  const sequenceBodyTemplate = (rowData, options) => {
    return Number(
      options.rowIndex +
        1 +
        (paymentVouchers?.page - 1) * paymentVouchers?.per_page
    );
  };
  const costCenterTempelate = (rowData) => {
    const center = costCenters?.data?.find(
      (center) => center?.id == rowData?.cost_center_id
    );
    return lang === "ar" ? center?.name_ar : center?.name_en;
  };
  const templateBody = (rowData) => {
    return (
      <div className="flex  gap-1">
        <SharedTooltip content="view" id="my-tooltip-1" />
        <SharedTooltip id="my-tooltip-2" content="edit" />
        <SharedTooltip id="my-tooltip-3" content="delete" />
        <IoMdEye
          size={25}
          data-tooltip-id="my-tooltip-1"
          className="!p-1 !text-black hover:cursor-pointer"
        />
        <MdOutlineEdit
          size={25}
          data-tooltip-id="my-tooltip-2"
          onClick={() => {
            navigate("/manager/create/payment/voucher", {
              state: { data: rowData },
            });
          }}
          className="!p-1 !text-black hover:cursor-pointer"
        />
        <MdOutlineDelete
          size={25}
          onClick={() => {
            setDeleteVoucher(true);
            setDelId(rowData?.id);
          }}
          data-tooltip-id="my-tooltip-3"
          className="!p-1 !text-black hover:cursor-pointer"
        />
      </div>
    );
  };
  return (
    <div>
      <div className="flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
        <SharedTooltip
          content={t("Keep_track_of_your_payment_vouchers")}
          position="left"
          id="Payment"
        />
        <span
          data-tooltip-id="Payment"
          className="font-semibold text-black/70 text-xl mt-2"
        >
          {t("PAYMENT VOUCHERS")}
          <span className="italic text-lg ml-1">
            ({paymentVouchers?.data?.length || 0}
            <span className="text-xs font-bold italic"> {t("Entries")}</span>)
          </span>
        </span>
        <div className="flex items-center gap-4">
          <SharedButton
            label={t("CreatePaymentVoucher")}
            icon="pi pi-plus"
            onClick={() => navigate("/manager/create/payment/voucher")}
            className="bg-gradient !w-full"
          />
        </div>
      </div>
      <div>
        <DataTable
          value={paymentVouchers?.data}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            body={sequenceBodyTemplate}
            header={t("no")}
            style={{ width: "3rem" }}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="date"
            header={t("Date")}
            body={(rowData) => formatDate(rowData?.date)}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            body={costCenterTempelate}
            header={t("Cost Center")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="total_amount"
            header={t("total_amount")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            body={templateBody}
            header={t("actions")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
        </DataTable>
        {paymentVouchers?.data?.length > 0 && (
          <CustomPagination
            currentPage={paymentVouchers?.page}
            rowsPerPage={paymentVouchers?.per_page}
            totalRecords={paymentVouchers?.total_pages}
            totalRecord={paymentVouchers?.total_record}
            onPageChange={onPageChange}
          />
        )}
      </div>
      <SharedConfirmDialog
        visible={deleteVoucher}
        setVisible={setDeleteVoucher}
        message="DoyouwanttoDelete?"
        rejectLabel="No"
        acceptLabel="Yes"
        onAccept={() => {
          dispatch(deletePaymentVoucher(delId))?.then(() => {
            setDeleteVoucher(false);
            dispatch(getPaymentVoucher({ page: 1, per_page: 10 }));
          });
        }}
        onReject={() => setDeleteVoucher(false)}
      />
      <Loader showLoader={isLoading} />
    </div>
  );
}

export default PaymentVoucher;
