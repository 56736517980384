import React, { useState } from "react";
import BalanceSheet from "../BalanceSheet/BalanceSheet";
import GeneralEntries from "../GeneralEntries/GeneralEntries";
import CashInflow from "../CashInflow/CashInflow";
import Income from "../Income/Income";
import Ledger from "../Ledger/Ledger";
import Trial from "../TrialBalance/TrialBalance";
import Loader from "../../Loader";
import { useSelector } from "react-redux";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import SharedButton from "../../components/Shared/SharedButton";
import { useTranslation } from "react-i18next";
import VatReport from "../Vat Report/VatReport";
import EmployeeMain from "../Employee/EmployeeMain";
import LeaveMain from "../Leave/LeaveMain";
import AttendanceMain from "../Attendance/AttendanceMain";
import DeductionMain from "../Deduction/DeductionMain";
import AllowanceMain from "../Allowance/AllowanceMain";
import PayslipMain from "../Payslip/PayslipMain";
import PayrunMain from "../Payrun/PayrunMain";

export default function PayrollMain() {
  const [activeTab, setActiveTab] = useState("employees");
  const { isLoading } = useSelector((state) => state.reports);
  const { t } = useTranslation();
  const tabList = [
    {
      label: t("employees"),
      comapre: "employees",
      command: () => setActiveTab("employees"),
    },
    {
      label: t("attendances"),
      comapre: "attendance",
      command: () => setActiveTab("attendance"),
    },
    {
      label: t("leaves"),
      comapre: "leave",
      command: () => setActiveTab("leave"),
    },
    {
      label: t("deductions"),
      comapre: "deduction",
      command: () => setActiveTab("deduction"),
    },
    {
      label: t("allowances"),
      comapre: "allowance",
      command: () => setActiveTab("allowance"),
    },
    {
      label: t("payruns"),
      comapre: "payrun",
      command: () => setActiveTab("payrun"),
    },
    {
      label: t("payslips"),
      comapre: "payslip",
      command: () => setActiveTab("payslip"),
    },
  ];

  return (
    <div>
      <div>
        <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
          <SharedTooltip
            content={t("Keep_track_of_payrolls")}
            position="left"
            id="report"
          />
          <span
            data-tooltip-id="report"
            className="font-semibold text-black/70 text-xl mt-2 "
          >
            {t("PAYROLL")}
            {/* <span className="italic text-lg ml-1">
            ({report?.length || 0}
            <span className="text-xs font-bold italic"> Entries</span>)
          </span> */}
          </span>

          {/* <SharedButton
          label="Create Report"
          icon="pi pi-plus"
          // onClick={() => setCreateDialogVisible(true)}
          className="bg-gradient"
        /> */}
        </div>{" "}
        {/* <div className=" col-span-5 border bg-gray-100 p-2 border-gray-100 rounded-md h-[75vh] overflow-auto"> */}
        <div className="">
          <ul className="grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner">
            {tabList.map((tab) => (
              <li>
                <a
                  className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                    activeTab === tab?.comapre
                      ? " bg-gradient rounded shadow text-white "
                      : ""
                  }`}
                  onClick={() => tab?.command()}
                >
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>

          <div className="rounded-md h-[70vh] overflow-auto">
            {activeTab === "employees" ? (
              <EmployeeMain />
            ) : activeTab === "leave" ? (
              <LeaveMain />
            ) : activeTab === "attendance" ? (
              <AttendanceMain />
            ) : activeTab === "deduction" ? (
              <DeductionMain />
            ) : activeTab === "allowance" ? (
              <AllowanceMain />
            ) : activeTab === "payrun" ? (
              <PayrunMain />
            ) : activeTab === "payslip" ? (
              <PayslipMain />
            ) : null}
          </div>
        </div>
        {/* </div> */}
      </div>
      <Loader showLoader={isLoading} />
    </div>
  );
}
