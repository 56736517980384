import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { CiTrash } from "react-icons/ci";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import {
  deleteTransactions,
  getTransactions,
  searchDatedTransaction,
  searchTransaction,
} from "../../store/slices/TransactionsSlice";
import CustomPagination from "../../components/Shared/CustomPagination";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import SharedButton from "../../components/Shared/SharedButton";

export const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = String(hours % 12 || 12).padStart(2, "0");

  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
};

export default function TransactionsTable({ searchQuery, dates }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = i18next.language;
  const { t } = useTranslation();
  const { transactions, page, per_page, total_pages, isLoading, total_record } =
    useSelector((state) => state.transaction);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [confirmationDialogVisible, setConfirmationDialogVisible] =
    useState(false);
  const [transId, setTransId] = useState();
  const [selectedTransactionEntries, setSelectedTransactionEntries] = useState(
    []
  );

  // useEffect(() => {
  //   dispatch(getTransactions({ page: 1, per_page: 10 }));
  // }, [dispatch]);

  const templateBody = (rowData) => (
    <div className="flex gap-1">
      <SharedTooltip content={t("view")} id="my-tooltip-1" />
      <SharedTooltip id="my-tooltip-2" content="edit" />
      <SharedTooltip id="my-tooltip-3" content="delete" />
      <MdOutlineRemoveRedEye
        size={25}
        data-tooltip-id="my-tooltip-1"
        className="!p-1 !text-black hover:cursor-pointer"
        onClick={() => {
          setSelectedTransactionEntries(rowData.transaction_entries);
          setCreateDialogVisible(true);
        }}
      />
      <MdOutlineEdit
        size={25}
        data-tooltip-id="my-tooltip-2"
        className="!p-1 !text-black hover:cursor-pointer"
        onClick={() => handleEdit(rowData)}
      />
      <CiTrash
        size={25}
        data-tooltip-id="my-tooltip-3"
        className="!p-1 !text-black hover:cursor-pointer"
        onClick={() => {
          setTransId(rowData?.id);
          setConfirmationDialogVisible(true);
        }}
      />
    </div>
  );
  const handleEdit = (rowData) => {
    // Navigate to the edit page with the transaction data
    navigate("/manager/transactions/edit", {
      state: { transactionData: rowData },
    });
  };

  const onPageChange = (newPage, newRowsPerPage) => {
    if (searchQuery.length > 3) {
      dispatch(searchTransaction({ page: newPage, per_page: newRowsPerPage }));
      if (dates) {
        dispatch(
          searchDatedTransaction({
            ...dates,
            page: newPage,
            per_page: newRowsPerPage,
          })
        );
      }
    } else {
      dispatch(getTransactions({ page: newPage, per_page: newRowsPerPage }));
    }
  };

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (page - 1) * per_page;
  };

  const [date, setDate] = useState("");
  const handleDateChange = (e) => {
    const formattedDate = formatDate(e.target.value);
    setDate(formattedDate);
  };

  const balanceBody = (data) => {
    return (
      <>
        {data?.total_debit} {t("SAR")}
      </>
    );
  };
  const balanceBodyy = (data) => {
    return (
      <>
        {data?.total_credit} {t("SAR")}
      </>
    );
  };

  return (
    <div>
      <DataTable
        value={transactions}
        tableStyle={{ minWidth: "50rem" }}
        rows={per_page}
        paginator={false}
      >
        <Column
          body={sequenceBodyTemplate}
          header={t("no")}
          style={{ width: "3rem" }}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        {/* <Column
          field="date"
          header={t("transactionDate")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        /> */}
        <Column
          field="date"
          header={t("transactionDate")}
          body={(rowData) => formatDate(rowData.date)}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="transaction_number"
          header={t("transaction_number")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="created_by"
          header={t("created_by")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="total_debit"
          body={balanceBody}
          header={t("total_debit")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          body={balanceBodyy}
          field="total_credit"
          header={t("total_credit")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="description"
          header={t("description")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          body={templateBody}
          header={t("actions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
      </DataTable>

      <Dialog
        header={t("Entries")}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <DataTable
          value={selectedTransactionEntries}
          tableStyle={{ minWidth: "50rem" }}
          rows={per_page}
          paginator={false}
        >
          <Column
            field={lang === "en" ? `account.name_en` : `account.name_ar`}
            header={t("AccountName")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="amount"
            header={t("debitAmount")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
            body={(rowData) => {
              return rowData?.type === "Debit" ? rowData?.amount : 0;
            }}
          />
          <Column
            field="payment_method"
            header={t("creditAmount")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
            body={(rowData) =>
              rowData.type === "Credit" ? rowData?.amount : 0
            }
          />
        </DataTable>
      </Dialog>
      <Dialog
        header={t("confirmation")}
        visible={confirmationDialogVisible}
        onHide={() => {
          setConfirmationDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-6/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <div>
          <p>Do you really want to Delete</p>
        </div>

        <div className="flex justify-end gap-6 mt-6">
          <SharedButton
            icon="pi pi-times"
            label={t("Cancel")}
            className="!p-1.5 !bg-red-400 hover:!bg-red-400"
            onClick={() => {
              setConfirmationDialogVisible(false);
            }}
          />
          <SharedButton
            type="submit"
            icon="pi pi-check"
            label={t("Submit")}
            className="!p-1.5 bg-gradient"
            onClick={() => {
              dispatch(deleteTransactions({ transaction_id: transId })).then(
                () => {
                  dispatch(getTransactions({ page: 1, per_page: 10 }));
                }
              );
              setConfirmationDialogVisible(false);
            }}
          />
        </div>
      </Dialog>
      {transactions?.length > 0 && (
        <CustomPagination
          currentPage={page}
          rowsPerPage={per_page}
          totalRecords={total_pages}
          totalRecord={total_record}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
}
