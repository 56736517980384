import React, { useEffect } from "react";
import SharedTooltip from "../components/Shared/SharedTooltip";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { HiOutlinePrinter } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCreditInvoicesList,
  printInvoice,
} from "../store/slices/InvoicesSlice";
import { formatDate } from "../Pages/Transactions/TransactionsTable";
import CustomPagination from "../components/Shared/CustomPagination";
import Loader from "../Loader";

function CreditTable() {
  const { creditInvoiceList, isLoading } = useSelector(
    (state) => state.invoice
  );
  console.log(creditInvoiceList);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePrintInvoice = async (id) => {
    try {
      const resultAction = await dispatch(printInvoice(id));
      if (printInvoice.fulfilled.match(resultAction)) {
        navigate("/manager/show-invoice");
      } else if (printInvoice.rejected.match(resultAction)) {
        console.error("Failed to print invoice:", resultAction.error);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const templateBody = (rowData) => (
    <div className="flex gap-1">
      <SharedTooltip content="print" id="print" />
      <SharedTooltip content="payment" id="payment" />
      <HiOutlinePrinter
        size={25}
        data-tooltip-id="print"
        className="!p-1 !text-black cursor-pointer"
        onClick={() => {
          handlePrintInvoice(rowData?.id);
        }}
      />
      {/* {rowData.paid !== true && (
        <LiaFileInvoiceDollarSolid
          size={25}
          data-tooltip-id="payment"
          className="!p-1 !text-black hover:cursor-pointer"
          onClick={() => {
            setSelectedEntry(rowData);
            setReceivePaymentDialogVisible(true);
          }}
        />
      )}
      <MdOutlineCancel
        size={25}
        data-tooltip-id="cancel"
        className="!p-1 !text-black cursor-pointer"
        onClick={() => {
          navigate("/manager/invoices/create", {
            state: { data: rowData, cancel: true },
          });
        }} */}
      {/* /> */}
    </div>
  );

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getCreditInvoicesList({ page: newPage, per_page: newRowsPerPage })
    );
  };
  useEffect(() => {
    dispatch(getCreditInvoicesList({ page: 1, per_page: 10 }));
  }, []);
  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1;
  };

  const balanceBody = (data) => {
    return (
      <>
        {data?.patient_share} {t("SAR")}
      </>
    );
  };
  const VatBody = (data) => {
    return (
      <>
        {data?.patient_tax} {t("SAR")}
      </>
    );
  };
  const invoicesList = [
    {
      data: [{}, {}],
    },
  ];
  const statusBody = (data) => {
    if (data?.paid) {
      return (
        <div className="bg-green-500 !w-[50px] p-1 text-center text-white rounded-md">
          {t("Paid")}
        </div>
      );
    } else {
      return (
        <div className="bg-red-500 p-1 !w-[50px] text-center text-white rounded-md">
          {t("Unpaid")}
        </div>
      );
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
        <SharedTooltip
          content={t("Keep_track_of_your_credits")}
          position="left"
          id="Items"
        />
        <span
          data-tooltip-id="Items"
          className="font-semibold text-black/70 text-xl mt-2"
        >
          {t("credit invoices")}
          <span className="italic text-lg ml-1">
            ({creditInvoiceList?.data?.length || 0}
            <span className="text-xs font-bold italic"> {t("Entries")}</span>)
          </span>
        </span>
      </div>
      <div>
        <DataTable
          value={creditInvoiceList?.data}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            body={sequenceBodyTemplate}
            header={t("no")}
            style={{ width: "3rem" }}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="date"
            header={t("date")}
            body={(rowData) => formatDate(rowData.date)}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="invoice_number"
            header={t("invoiceNumber")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="patient.name"
            header={`${t("patient")} ${t("name")}`}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="patient_share"
            header={t("patientShare")}
            body={balanceBody}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="patient_tax"
            header={t("totalVAT")}
            body={VatBody}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="file_number"
            header={t("fileNumber")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary ",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="nationality"
            header={t("nationality")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            body={statusBody}
            field="paid"
            header={t("status")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="description"
            header={t("description")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            body={templateBody}
            header={t("actions")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
        </DataTable>
        {/* <Loader showLoader={isLoading} />k */}
        {creditInvoiceList?.data?.length > 0 && (
          <CustomPagination
            currentPage={creditInvoiceList?.page}
            rowsPerPage={creditInvoiceList?.per_page}
            totalRecords={creditInvoiceList?.total_pages}
            totalRecord={creditInvoiceList?.total_record}
            onPageChange={onPageChange}
          />
        )}
        {/* <Loader showLoader={isLoading} /> */}
      </div>
    </div>
  );
}

export default CreditTable;
