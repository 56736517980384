import React, { useEffect, useState } from "react";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import SharedButton from "../../components/Shared/SharedButton";
import CreateCustomer from "./CreateCustomer";
import CustomerTable from "./CustomerTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomers,
  getCustomersList,
  getSearchedCustomer,
} from "../../store/slices/CustomerSlice";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
export default function CustomerMain() {
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomersList({ page: 1, per_page: 10 }));
  }, []);

  const { customersList } = useSelector((state) => state.customers);
  const { isLoading } = useSelector((state) => state.customers);
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const [hasCalledList, setHasCalledList] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState(null);

  const handleSearch = async () => {
    if (searchValue.trim().length >= 8) {
      const data = await dispatch(
        getSearchedCustomer({ query: searchValue.trim() })
      );

      // Update the invoices list with the search results
      if (data?.data && data?.data.length > 0) {
        setFilteredInvoices(data);
      }
    } else {
      setFilteredInvoices(null);
      dispatch(getCustomersList({ page: 1, per_page: 10 }));
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
          <SharedTooltip
            content={t("Keep_track_of_your_customers")}
            position="left"
            id="Items"
          />
          <span
            data-tooltip-id="Items"
            className="font-semibold text-black/70 text-xl mt-2 "
          >
            {t("CUSTOMERS")}
            <span className="italic text-lg ml-1">
              ( {customersList?.total_record}
              <span className="text-xs font-bold italic"> {t("Entries")}</span>)
            </span>
          </span>

          <div className="flex items-center gap-4">
            <div className="flex gap-2">
              <input
                placeholder={t("Search by Name or Document ID")}
                type="text"
                className="border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 hover:border-primary !outline-none !shadow-none"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  if (e.target.value.length < 8 && !hasCalledList) {
                    dispatch(getCustomersList({ page: 1, per_page: 10 }));
                    setHasCalledList(true);
                  } else if (e.target.value.length >= 8) {
                    setHasCalledList(false);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <span
                className="!bg-primary !text-white rounded-md p-2.5"
                onClick={() => handleSearch()}
              >
                <FaSearch />
              </span>
            </div>

            <SharedButton
              label={t("CreateCustomer")}
              icon="pi pi-plus"
              onClick={() => setCreateDialogVisible(true)}
              className="bg-gradient"
            />
          </div>
        </div>
        {/* <CustomerTable data={customersList} /> */}

        <CustomerTable
          data={filteredInvoices?.length ? filteredInvoices : customersList}
        />
        <CreateCustomer
          setCreateDialogVisible={setCreateDialogVisible}
          createDialogVisible={createDialogVisible}
        />
      </div>
      <Loader showLoader={isLoading} />
    </>
  );
}
