import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SharedButton from "../../components/Shared/SharedButton";
import CreateAccount from "./CreateAccount";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import AccountsTable from "./AccountsTable";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { getAccounts } from "../../store/slices/AcountsSlice";
import SearchInput from "../../components/Shared/Search";
import { Dropdown } from "primereact/dropdown";

export default function AccountsMain() {
  const dispatch = useDispatch();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const { t } = useTranslation();

  const { accounts, isLoading } = useSelector((state) => state.acount);
  const [activeTab, setActiveTab] = useState("Asset");

  useEffect(() => {
    dispatch(getAccounts({ account_type: activeTab }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    const filterNode = (node, query) => {
      const lowerQuery = query.toLowerCase();
      const matchNameEn = node.data.name_en?.toLowerCase().includes(lowerQuery);
      const matchNameAr = node.data.name_ar?.toLowerCase().includes(lowerQuery);
      const matchDigitsOrNameEn = node.data.name_en
        ?.split("-")
        .some((part) => part.toLowerCase().includes(lowerQuery));
      const matchDigitsOrNameAr = node.data.name_ar
        ?.split("-")
        .some((part) => part.toLowerCase().includes(lowerQuery));

      return (
        matchNameEn || matchNameAr || matchDigitsOrNameEn || matchDigitsOrNameAr
      );
    };

    const filterTree = (nodes, query) => {
      return nodes.flatMap((node) => {
        if (filterNode(node, query)) {
          return [node];
        }

        if (node.children) {
          const filteredChildren = filterTree(node.children, query);
          if (filteredChildren.length > 0) {
            return filteredChildren;
          }
        }
        return [];
      });
    };

    if (!searchQuery) {
      setFilteredAccounts(accounts || []);
    } else {
      const filtered = filterTree(accounts, searchQuery);
      setFilteredAccounts(filtered);
    }
  }, [searchQuery, accounts]);

  const tabList = [
    { label: t("Asset"), value: "Asset" },
    { label: t("Liability"), value: "Liability" },
    { label: t("Revenue"), value: "Revenue" },
    { label: t("Equity"), value: "Equity" },
    { label: t("Expense"), value: "Expense" },
  ];

  return (
    <div>
      <div className="flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
        <SharedTooltip
          content={t("Keep_track_of_your_accounts")}
          position="left"
          id="Items"
        />
        <span
          data-tooltip-id="Items"
          className="font-semibold text-black/70 text-xl mt-2"
        >
          {t("ACCOUNTS")}
          {/* <span className="italic text-lg ml-1">
            ({accounts?.length || 0}
            <span className="text-xs font-bold italic"> {t("Entries")}</span>)
          </span> */}
        </span>
        <div className="flex items-center gap-4">
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={t("Search_by_Code_or_name")}
          />
          <SharedButton
            label={t("CreateAccount")}
            icon="pi pi-plus"
            onClick={() => setCreateDialogVisible(true)}
            className="bg-gradient !w-full"
          />
        </div>
      </div>

      <div className="yee">
        <ul className="grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner">
          {tabList.map((tab) => (
            <li key={tab.value}>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === tab.value
                    ? "bg-gradient rounded shadow text-white"
                    : ""
                }`}
                onClick={() => setActiveTab(tab.value)}
              >
                {tab.label}
              </a>
            </li>
          ))}
        </ul>
        <div className="rounded-md h-[70vh] overflow-auto">
          <AccountsTable
            data={filteredAccounts}
            setUpdateData={setUpdateData}
            setCreateDialogVisible={setCreateDialogVisible}
            activeTab={activeTab}
          />
        </div>
      </div>
      <CreateAccount
        createDialogVisible={createDialogVisible}
        setCreateDialogVisible={setCreateDialogVisible}
        isLoading={isLoading}
        activeTab={activeTab}
        setUpdateData={setUpdateData}
        updateData={updateData}
      />
      {isLoading && <Loader showLoader={isLoading} />}
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import SharedButton from "../../components/Shared/SharedButton";
// import CreateAccount from "./CreateAccount";
// import SharedTooltip from "../../components/Shared/SharedTooltip";
// import AccountsTable from "./AccountsTable";
// import Loader from "../../Loader";
// import { useTranslation } from "react-i18next";
// import { getAccounts } from "../../store/slices/AcountsSlice";
// import SearchInput from "../../components/Shared/Search";
// import { Dropdown } from "primereact/dropdown";

// export default function AccountsMain() {
//   const dispatch = useDispatch();
//   const [createDialogVisible, setCreateDialogVisible] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filteredAccounts, setFilteredAccounts] = useState([]);
//   const { t } = useTranslation();

//   const { accounts, isLoading } = useSelector((state) => state.acount);

//   const [activeTab, setActiveTab] = useState("Asset");
//   const options = [
//     { value: "Asset", label: "Asset" },
//     { value: "Liability", label: "Liability" },
//     { value: "Revenue", label: "Revenue" },
//     { value: "Equity", label: "Equity" },
//     { value: "Expense", label: "Expense" },
//   ];

//   const tabList = [
//     {
//       label: t("Asset"),
//       comapre: "Asset",
//       command: () => setActiveTab("Asset"),
//     },
//     {
//       label: t("Liability"),
//       comapre: "Liability",
//       command: () => setActiveTab("Liability"),
//     },
//     {
//       label: t("Revenue"),
//       comapre: "Revenue",
//       command: () => setActiveTab("Revenue"),
//     },

//     {
//       label: t("Equity"),
//       comapre: "Equity",
//       command: () => setActiveTab("Equity"),
//     },

//     {
//       label: t("Expense"),
//       comapre: "Expense",
//       command: () => setActiveTab("Expense"),
//     },
//   ];

//   useEffect(() => {
//     dispatch(
//       getAccounts({
//         account_type: "Asset",
//       })
//     );
//   }, [dispatch]);

//   useEffect(() => {
//     const filterNode = (node, query) => {
//       const lowerQuery = query.toLowerCase();
//       const matchNameEn = node.data.name_en?.toLowerCase().includes(lowerQuery);
//       const matchNameAr = node.data.name_ar?.toLowerCase().includes(lowerQuery);
//       const matchDigitsOrNameEn = node.data.name_en
//         ?.split("-")
//         .some((part) => part.toLowerCase().includes(lowerQuery));
//       const matchDigitsOrNameAr = node.data.name_ar
//         ?.split("-")
//         .some((part) => part.toLowerCase().includes(lowerQuery));

//       return (
//         matchNameEn || matchNameAr || matchDigitsOrNameEn || matchDigitsOrNameAr
//       );
//     };

//     const filterTree = (nodes, query) => {
//       return nodes.flatMap((node) => {
//         if (filterNode(node, query)) {
//           return [node];
//         }

//         if (node.children) {
//           const filteredChildren = filterTree(node.children, query);
//           if (filteredChildren.length > 0) {
//             return filteredChildren;
//           }
//         }
//         return [];
//       });
//     };

//     if (!searchQuery) {
//       setFilteredAccounts(accounts || []);
//     } else {
//       const filtered = filterTree(accounts, searchQuery);
//       setFilteredAccounts(filtered);
//     }
//   }, [searchQuery, accounts]);

//   return (
//     <div>
//       <div className="flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
//         <SharedTooltip
//           content={t("Keep_track_of_your_accounts")}
//           position="left"
//           id="Items"
//         />
//         <span
//           data-tooltip-id="Items"
//           className="font-semibold text-black/70 text-xl mt-2"
//         >
//           {t("ACCOUNTS")}
//           <span className="italic text-lg ml-1">
//             ({accounts?.length || 0}
//             <span className="text-xs font-bold italic"> {t("Entries")}</span>)
//           </span>
//         </span>
//         <div className="flex items-center gap-4">
//           {/* <Dropdown
//             options={options}
//             // optionLabel={optionLabel}
//             // optionValue={optionValue}
//             onChange={(e) => {
//               dispatch(
//                 getAccounts({
//                   account_type: e.target.value,
//                 })
//               );
//             }}
//             placeholder="Select Account Type"
//             className={`!w-full  !p-0 !border-gray-300 rounded-md hover:!border-primary !outline-none !shadow-none`}
//             pt={{
//               input: { className: "!w-full !p-1.5" },
//               list: { className: "!py-0" },
//             }}
//           /> */}
//           <SearchInput
//             searchQuery={searchQuery}
//             setSearchQuery={setSearchQuery}
//             placeholder={t("Search_by_Code_or_name")}
//           />
//           <SharedButton
//             label={t("CreateAccount")}
//             icon="pi pi-plus"
//             onClick={() => setCreateDialogVisible(true)}
//             className="bg-gradient !w-full"
//           />
//         </div>
//       </div>

//       <div className="yee">
//       <ul className="grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner">
//             {tabList.map((tab) => (
//               <li>
//                 <a
//                   className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
//                     activeTab === tab?.comapre
//                       ? " bg-gradient rounded shadow text-white "
//                       : ""
//                   }`}
//                   onClick={() => tab?.command()}
//                 >
//                   {tab.label}
//                 </a>
//               </li>
//             ))}
//           </ul>
//           {/* border bg-white p-2 border-gray-100 */}
//         <div className="rounded-md h-[70vh] overflow-auto">
//           {/* <ul className="grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner">
//             {tabList.map((tab) => (
//               <li>
//                 <a
//                   className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
//                     activeTab === tab?.comapre
//                       ? " bg-gradient rounded shadow text-white "
//                       : ""
//                   }`}
//                   onClick={() => tab?.command()}
//                 >
//                   {tab.label}
//                 </a>
//               </li>
//             ))}
//           </ul> */}

//           <div>
//             {activeTab === "Asset"
//               ? dispatch(
//                   getAccounts({
//                     account_type: "Asset",
//                   })
//                 )
//               : activeTab === "Liability"
//               ? dispatch(
//                   getAccounts({
//                     account_type: "Liability",
//                   })
//                 )
//               : activeTab === "Revenue"
//               ? dispatch(
//                   getAccounts({
//                     account_type: "Revenue",
//                   })
//                 )
//               : activeTab === "Equity"
//               ? dispatch(
//                   getAccounts({
//                     account_type: "Equity",
//                   })
//                 )
//               : activeTab === "Expense"
//               ? dispatch(
//                   getAccounts({
//                     account_type: "Expense",
//                   })
//                 )
//               : null}
//           </div>
//           <AccountsTable data={filteredAccounts} />
//         </div>
//       </div>

//       {/* <AccountsTable data={filteredAccounts} /> */}

//       <CreateAccount
//         createDialogVisible={createDialogVisible}
//         setCreateDialogVisible={setCreateDialogVisible}
//         isLoading={isLoading}
//       />
//       {isLoading && <Loader showLoader={isLoading} />}
//     </div>
//   );
// }

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import SharedButton from "../../components/Shared/SharedButton";
// import CreateAccount from "./CreateAccount";
// import SharedTooltip from "../../components/Shared/SharedTooltip";
// import AccountsTable from "./AccountsTable";
// import Loader from "../../Loader";
// import { useTranslation } from "react-i18next";
// import { getAccounts } from "../../store/slices/AcountsSlice";
// import SearchInput from "../../components/Shared/Search";

// export default function AccountsMain() {
//   const dispatch = useDispatch();
//   const [createDialogVisible, setCreateDialogVisible] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filteredAccounts, setFilteredAccounts] = useState([]);
//   const { t } = useTranslation();

//   const { accounts, isLoading } = useSelector((state) => state.acount);

//   useEffect(() => {
//     dispatch(getAccounts());
//   }, [dispatch]);

//   useEffect(() => {
//     const filterNode = (node, query) => {
//       const lowerQuery = query.toLowerCase();
//       const matchNameEn = node.data.name_en?.toLowerCase().includes(lowerQuery);
//       const matchNameAr = node.data.name_ar?.toLowerCase().includes(lowerQuery);
//       const matchDigitsOrNameEn = node.data.name_en?.split("-").some(part => part.toLowerCase().includes(lowerQuery));
//       const matchDigitsOrNameAr = node.data.name_ar?.split("-").some(part => part.toLowerCase().includes(lowerQuery));

//       return matchNameEn || matchNameAr || matchDigitsOrNameEn || matchDigitsOrNameAr;
//     };

//     const filterTree = (nodes, query) => {
//       return nodes.flatMap((node) => {
//         if (filterNode(node, query)) {
//           return [node];
//         }

//         if (node.children) {
//           const filteredChildren = filterTree(node.children, query);
//           if (filteredChildren.length > 0) {
//             return filteredChildren;
//           }
//         }
//           return [];
//       });
//     };

//     if (!searchQuery) {
//       setFilteredAccounts(accounts || []);
//     } else {
//       const filtered = filterTree(accounts, searchQuery);
//       setFilteredAccounts(filtered);
//     }
//   }, [searchQuery, accounts]);

//   return (
//     <div>
//       <div className="flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
//         <SharedTooltip
//           content={t("Keep_track_of_your_accounts")}
//           position="left"
//           id="Items"
//         />
//         <span
//           data-tooltip-id="Items"
//           className="font-semibold text-black/70 text-xl mt-2"
//         >
//           {t("ACCOUNTS")}
//           <span className="italic text-lg ml-1">
//             ({accounts?.length || 0}
//             <span className="text-xs font-bold italic"> {t("Entries")}</span>)
//           </span>
//         </span>
//         <div className="flex items-center gap-4">
//           <SearchInput
//             searchQuery={searchQuery}
//             setSearchQuery={setSearchQuery}
//             placeholder={t("Search_by_Code_or_name")}
//           />
//           <SharedButton
//             label={t("CreateAccount")}
//             icon="pi pi-plus"
//             onClick={() => setCreateDialogVisible(true)}
//             className="bg-gradient"
//           />
//         </div>
//       </div>

//       <AccountsTable data={filteredAccounts} />

//       <CreateAccount
//         createDialogVisible={createDialogVisible}
//         setCreateDialogVisible={setCreateDialogVisible}
//         isLoading={isLoading}
//       />
//       {isLoading && <Loader showLoader={isLoading} />}
//     </div>
//   );
// }
