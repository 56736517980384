import React, { useEffect, useRef, useState } from "react";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import LedgerTable from "./LedgerTable";
import { getLedgerList } from "../../store/slices/ReportSlice";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { getOneMonthAgo } from "../CashInflow/CashInflow";
import { Card } from "primereact/card";
import LedgerAssets from "./LedgerAssets";
import LedgerEquity from "./LedgerEquity";
import LedgerLiabilities from "./LedgerLiabilities";
import LedgerExpenses from "./LedgerExpenses";
import LedgerRevenue from "./LedgerRevenue";
import * as XLSX from "xlsx";
import { Menu } from "primereact/menu";
export default function Ledger() {
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [startDate, setStartDate] = useState(getOneMonthAgo(endDate));
  const { isLoading, ledgerList } = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);

  const handleSubmit = () => {
    if (startDate && endDate) {
      const payload = {
        start_date: startDate,
        end_date: endDate,
        page: 1,
        per_page: 20,
      };
      dispatch(getLedgerList(payload));
    }
  };

  useEffect(() => {
    const payload = {
      start_date: startDate,
      end_date: endDate,
      page: 1,
      per_page: 20,
    };
    dispatch(getLedgerList(payload));
  }, []);

  const { t } = useTranslation();

  const handlePrint = () => {
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write("<html><head><title>Balance Sheet</title>");
    doc.write(
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">'
    );

    doc.write("<style>");
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0; /* Removes the default margins */
        }
        body {
          margin: 1cm; /* Adjust the margins to your needs */
        }
        /* Hide browser print headers and footers */
        header, footer {
          display: none;
        }
      }
    `);
    doc.write("</style>");

    doc.write("</head><body>");
    doc.write(printContents);
    doc.write("</body></html>");
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };
  const extractCategoryData = (ledgerList, category) => {
    console.log(`Extracting data for category: ${category}`);
    console.log("LedgerList:", ledgerList);

    const categoryData = ledgerList[category] || {};
    console.log("Category data:", categoryData);

    const result = [
      [category.charAt(0).toUpperCase() + category.slice(1)],
      ["Account", "Balance"],
    ];

    if (Array.isArray(categoryData.accounts)) {
      categoryData.accounts.forEach((item) => {
        if (item && item.name_en && item.balance !== undefined) {
          result.push([item.name_en, item.balance]);
        } else {
          console.warn("Invalid item in accounts:", item);
        }
      });
    } else {
      console.warn(`No accounts array found for category: ${category}`);
    }

    console.log("Extracted result:", result);
    return result;
  };

  const handleExcelExport = () => {
    console.log("Starting Excel export");
    console.log("LedgerList:", ledgerList);

    const wb = XLSX.utils.book_new();

    const categories = ["assets", "liabilities", "expenses", "revenue"];

    categories.forEach((category) => {
      console.log(`Processing category: ${category}`);
      const data = extractCategoryData(ledgerList, category);

      if (data.length > 2) {
        // Check if we have any data beyond headers
        const ws = XLSX.utils.aoa_to_sheet(data);

        const colWidths = [{ wch: 30 }, { wch: 15 }];
        ws["!cols"] = colWidths;

        XLSX.utils.book_append_sheet(
          wb,
          ws,
          category.charAt(0).toUpperCase() + category.slice(1)
        );
        console.log(`Added sheet for ${category}`);
      } else {
        console.warn(`No data to add for category: ${category}`);
      }
    });

    // Add a summary sheet
    const summaryData = [
      ["Summary"],
      ["Category", "Total"],
      ["Assets", ledgerList.totals?.total_assets],
      ["Liabilities", ledgerList.totals?.total_liabilities],
      ["Expenses", ledgerList.totals?.total_expenses],
      ["Revenue", ledgerList.totals?.total_revenue],
    ];
    console.log("Summary data:", summaryData);

    const summaryWs = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(wb, summaryWs, "Summary");

    try {
      XLSX.writeFile(wb, "Ledger Report.xlsx");
      console.log("Excel file written successfully");
    } catch (error) {
      console.error("Error writing Excel file:", error);
    }
  };
  const items = [
    {
      label: t("EXCEL"),
      icon: "pi pi-file-excel",
      command: handleExcelExport,
    },
    {
      label: t("PDF"),
      icon: "pi pi-print",
      command: handlePrint,
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex gap-5 flex-wrap">
          <div className="flex  items-center gap-2">
            <label className="text-sm">{t("StartDate")}</label>
            <input
              required
              type="date"
              name="start_date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              id="dobDate"
              autoComplete="off"
              className="p-[0.33rem] border rounded-md"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="text-sm">{t("EndDate")}</label>
            <input
              required
              type="date"
              name="end_date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              id="dobDate"
              autoComplete="off"
              className="p-[0.33rem] border rounded-md"
            />
          </div>
          <div className="">
            <SharedButton
              label={t("Submit")}
              className="bg-gradient"
              onClick={handleSubmit}
              disabled={!startDate || !endDate}
            />
          </div>
        </div>

        <div className="">
          <SharedButton
            label={`${t("Export")}`}
            icon="pi pi-download"
            iconPos="right"
            onClick={(e) => menuRef.current.toggle(e)}
            className="bg-gradient"
          />
          <Menu
            pt={{
              root: { className: "!py-0 !bg-none" },
              content: { className: "!p-0 !bg-none !text-gray-500" },
              action: { className: "!py-1.5 !px-2 !bg-none !text-gray-500" },
              menuitem: { className: "!bg-none" },
              menu: { className: "!bg-gray-100" },
              icon: { className: "!text-gray-500" },
            }}
            model={items}
            popup
            ref={menuRef}
          />
        </div>
      </div>

      <div className="this_div" ref={balanceSheetRef}>
        <div className="!pb-8">
          <div className=" one">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
                body: { className: "!p-0" },
                root: { className: "!shadow-none" },
              }}
            >
              <div className="">
                <LedgerAssets data={ledgerList} />
              </div>

              <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5 flex justify-end">
                  <div className="mx-16 !mt-2 flex gap-2">
                    <span className="font-semibold text-black/70 text-lg">
                      {`${t("assets")} ${t("Total")}`}
                    </span>
                    <span className="font-semibold text-black/60 text-lg">
                      {ledgerList?.totals?.total_assets
                        ? ledgerList?.totals?.total_assets.toFixed(2)
                        : "0"}{" "}
                      <span>{t("SAR")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" two">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
                body: { className: "!p-0" },
                root: { className: "!shadow-none" },
              }}
            >
              <div className="">
                <LedgerEquity data={ledgerList} />
              </div>

              <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5 flex justify-end">
                  <div className="mx-16 !mt-2 flex gap-2">
                    <span className="font-semibold text-black/70 text-lg">
                      {`${t("Equity")} ${t("Total")}`}
                    </span>
                    <span className="font-semibold text-black/60 text-lg">
                      {ledgerList?.totals?.total_equity
                        ? ledgerList?.totals?.total_equity.toFixed(2)
                        : "0"}{" "}
                      <span>{t("SAR")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" three">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
                body: { className: "!p-0" },
                root: { className: "!shadow-none" },
              }}
            >
              <div className="">
                <LedgerExpenses data={ledgerList} />
              </div>

              <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5 flex justify-end">
                  <div className="mx-16 !mt-2 flex gap-2">
                    <span className="font-semibold text-black/70 text-lg">
                      {`${t("Expenses")} ${t("Total")}`}
                    </span>
                    <span className="font-semibold text-black/60 text-lg">
                      {ledgerList?.totals?.total_expenses
                        ? ledgerList?.totals?.total_expenses.toFixed(2)
                        : "0"}{" "}
                      <span>{t("SAR")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" four">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
                body: { className: "!p-0" },
                root: { className: "!shadow-none" },
              }}
            >
              <div className="">
                {/* <Liabilities data={balanceSheet} /> */}
                <LedgerLiabilities data={ledgerList} />
              </div>

              <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5 flex justify-end">
                  <div className="mx-16 !mt-2 flex gap-2">
                    <span className="font-semibold text-black/70 text-lg">
                      {`${t("Liabilities")} ${t("Total")}`}
                    </span>
                    <span className="font-semibold text-black/60 text-lg">
                      {ledgerList?.totals?.total_liabilities
                        ? ledgerList?.totals?.total_liabilities.toFixed(2)
                        : "0"}{" "}
                      <span>{t("SAR")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" five">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
                body: { className: "!p-0" },
                root: { className: "!shadow-none" },
              }}
            >
              <div className="">
                <LedgerRevenue data={ledgerList} />
              </div>

              <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5 flex justify-end">
                  <div className="mx-16 !mt-2 flex gap-2">
                    <span className="font-semibold text-black/70 text-lg">
                      {`${t("Revenues")} ${t("Total")}`}
                    </span>
                    <span className="font-semibold text-black/60 text-lg">
                      {ledgerList?.totals?.total_revenue
                        ? ledgerList?.totals?.total_revenue.toFixed(2)
                        : "0"}{" "}
                      <span>{t("SAR")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="this_div" ref={balanceSheetRef}>
        <div className="pb-8">
          <div className="title !my-8">
            <div className="flex justify-center">
              <div className="mx-10 !mt-1">
                <div className="font-semibold text-black/70 text-xl">
                  {`${t("Ledger")}`}
                </div>{" "}
              </div>
            </div>
          </div>

          <div className="">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
              }}
            >
              <div className="">
                <LedgerTable data={ledgerList?.data} />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <iframe ref={iframeRef} style={{ display: "none" }}></iframe>
      {/* <Loader showLoader={isLoading} /> */}
    </div>
  );
}
