import { Dialog } from "primereact/dialog";
import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SharedButton from "../../components/Shared/SharedButton";
import Loader from "../../Loader";
import SharedInputText from "../../components/Shared/SharedInputText";
import {
  createNewCostCenter,
  editCostCenter,
  getCostCenterList,
} from "../../store/slices/CostCenterSlice";

export default function CreateCostCenter({
  setCreateDialogVisible,
  createDialogVisible,
  dispatch,
  editData = null,
}) {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.costCenter);

  const initialValues = {
    name_en: editData?.name_en || "",
    name_ar: editData?.name_ar || "",
    description: editData?.description || "",

    business_id: editData?.business_id || user?.business_id,
    branch_id: editData?.branch_id || user?.branch_id,
  };

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required(t("required")),
    name_ar: Yup.string().required(t("required")),
    description: Yup.string().required(t("required")),
  });

  return (
    <>
      <Dialog
        header={t("CreateCostCenter")}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            if (editData) {
              const resultAction = await dispatch(
                editCostCenter({ data: values, id: editData?.id })
              );
              if (editCostCenter.fulfilled.match(resultAction)) {
                setCreateDialogVisible(false);
                resetForm();
                dispatch(getCostCenterList({ page: 1, per_page: 10 }));
              }
            } else {
              const resultAction = await dispatch(createNewCostCenter(values));
              if (createNewCostCenter.fulfilled.match(resultAction)) {
                setCreateDialogVisible(false);
                resetForm();
                dispatch(getCostCenterList({ page: 1, per_page: 10 }));
              }
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-3 gap-4 w-full">
                <Field
                  as={SharedInputText}
                  label={t("name_en")}
                  name="name_en"
                  placeholder={`${t("enter")} ${t("name_en")}`}
                  className="!p-1.5"
                />
                <Field
                  as={SharedInputText}
                  label={t("name_ar")}
                  name="name_ar"
                  placeholder={`${t("enter")} ${t("name_ar")}`}
                  className="!p-1.5"
                />

                <Field
                  as={SharedInputText}
                  label={t("description")}
                  name="description"
                  placeholder={`${t("enter")} ${t("description")}`}
                  className="!p-1.5"
                />
              </div>

              <div className="flex justify-end gap-6 mt-6">
                <SharedButton
                  icon="pi pi-times"
                  label={t("Cancel")}
                  className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                  onClick={() => {
                    setCreateDialogVisible(false);
                    formik.resetForm();
                  }}
                  type="button"
                />
                <SharedButton
                  icon="pi pi-check"
                  disabled={isLoading}
                  label={editData ? t("Update") : t("Submit")}
                  className="!p-1.5 bg-gradient"
                  type="submit"
                />
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
      <Loader showLoader={isLoading} />
    </>
  );
}
