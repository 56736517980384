import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SharedButton from "../../components/Shared/SharedButton";
import CreateTransaction from "./CreateTransaction";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import TransactionsTable from "./TransactionsTable";
import {
  getTransactions,
  searchDatedTransaction,
  searchTransaction,
} from "../../store/slices/TransactionsSlice";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/Shared/Search";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import toast from "react-hot-toast";

export default function TransactionsMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [dates, setDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    dispatch(getTransactions({ page: 1, per_page: 10 }));
  }, [dispatch]);

  const { transactions, isLoading, page, per_page, total, total_record } =
    useSelector((state) => state.transaction);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (searchQuery.length > 3) {
      dispatch(
        searchTransaction({
          query: searchQuery,
          page: 1,
          per_page: 10,
        })
      );
      if (searchQuery < 3) {
        dispatch(getTransactions({ page: 1, per_page: 10 }));
      }
    }
  }, [searchQuery]);
  return (
    <div className="">
      <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
        <SharedTooltip
          content={t("Keep_track_of_your_transactions")}
          position="left"
          id="transactions"
        />
        <span
          data-tooltip-id="transactions"
          className="font-semibold text-black/70 text-xl mt-2 "
        >
          {t("JournalEntries")}
          <span className="italic text-lg ml-1">
            ({total_record || 0}
            <span className="text-xs font-bold italic"> {t("Entries")}</span>)
          </span>
        </span>
        <div className="flex items-center gap-4">
          <input
            type="text"
            className={`border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 hover:border-primary !outline-none !shadow-none`}
            placeholder={t("serch_by_number")}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            aria-label={t("serch_by_number")}
          />
          <SharedButton
            label={t("CreateJournalEntries")}
            icon="pi pi-plus"
            onClick={() => navigate("/manager/transactions/create")}
            // onClick={() => setCreateDialogVisible(true)}
            className="bg-gradient"
          />
          <Button
            label="Apply Filter"
            severity="warning"
            outlined
            className="!p-1.5 font-extralight !border-primary !text-primary"
            onClick={() => setVisible(true)}
          />
        </div>
      </div>

      {/* <CreateTransaction
        createDialogVisible={createDialogVisible}
        setCreateDialogVisible={setCreateDialogVisible}
      /> */}
      <Sidebar
        visible={visible}
        position="right"
        header="Filter"
        onHide={() => setVisible(false)}
        pt={{
          header: { className: "!justify-between" },
        }}
      >
        <div className="flex justify-between flex-col h-[85vh]">
          <div className="flex flex-col gap-3">
            <div>
              <label htmlFor="start">Start Date</label>
              <input
                id="start"
                type="date"
                className="w-full border rounded-md !p-1.5"
                onChange={(e) => {
                  setDates((prevDates) => ({
                    ...prevDates,
                    start_date: e.target.value,
                  }));
                }}
              />
            </div>
            <div>
              <label htmlFor="end">End Date</label>
              <input
                id="end"
                type="date"
                className="w-full border rounded-md !p-1.5"
                onChange={(e) => {
                  setDates((prevDates) => ({
                    ...prevDates,
                    end_date: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <SharedButton
              label={t("submit")}
              className="bg-gradient w-full"
              onClick={() => {
                if (dates.end_date === "" || dates.start_date === "") {
                  toast.error("Please Enter Dates");
                } else {
                  dispatch(
                    searchDatedTransaction({
                      ...dates,
                      page: 1,
                      per_page: 10,
                    })
                  );
                }
              }}
            />
          </div>
        </div>
      </Sidebar>
      <TransactionsTable
        data={transactions}
        searchQuery={searchQuery}
        dates={dates}
      />
      <Loader showLoader={isLoading} />
    </div>
  );
}
