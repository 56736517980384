import React, { useEffect, useRef, useState } from "react";
import SharedButton from "../../components/Shared/SharedButton";
import IncomeCard from "./IncomeCard";
import { useDispatch, useSelector } from "react-redux";
import { getIncomeStatement } from "../../store/slices/ReportSlice";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { getOneMonthAgo } from "../CashInflow/CashInflow";
import * as XLSX from "xlsx";
import { Menu } from "primereact/menu";

export default function Income() {
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [startDate, setStartDate] = useState(getOneMonthAgo(endDate));
  const { loading, incomeStatement } = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);
  const menuRef = useRef(null);
  const { t } = useTranslation();
  // loading, cashFlow, bankFlow
  const handleSubmit = () => {
    if (startDate && endDate) {
      const payload = {
        start_date: startDate,
        end_date: endDate,
      };
      dispatch(getIncomeStatement(payload));
    }
  };

  useEffect(() => {
    const payload = {
      start_date: startDate,
      end_date: endDate,
    };
    dispatch(getIncomeStatement(payload));
  }, []);

  const handlePrint = () => {
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write("<html><head><title>Balance Sheet</title>");
    doc.write(
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">'
    );

    doc.write("<style>");
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0; /* Removes the default margins */
        }
        body {
          margin: 1cm; /* Adjust the margins to your needs */
        }
        /* Hide browser print headers and footers */
        header, footer {
          display: none;
        }
      }
    `);
    doc.write("</style>");

    doc.write("</head><body>");
    doc.write(printContents);
    doc.write("</body></html>");
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  const extractData = (element) => {
    const data = [];
    const title = element.querySelector(".text-lg").textContent;
    data.push([title]);
    data.push(["Item", "Amount"]);

    const items = element.querySelectorAll(".flex.justify-between");
    items.forEach((item) => {
      const name = item.querySelector(".font-medium").textContent.replace(":", "");
      const amount = item.querySelector("span:last-child").textContent;
      data.push([name, amount]);
    });

    return data;
  };

  const handleExcelExport = () => {
    const wb = XLSX.utils.book_new();
    const data = extractData(balanceSheetRef.current);
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Set column widths
    const colWidths = [{ wch: 20 }, { wch: 20 }];
    ws["!cols"] = colWidths;

    XLSX.utils.book_append_sheet(wb, ws, "Income Statement");
    XLSX.writeFile(wb, "Income Statement.xlsx");
  };

  const items = [
    {
      label: t("EXCEL"),
      icon: "pi pi-file-excel",
      command: handleExcelExport,
    },
    {
      label: t("PDF"),
      icon: "pi pi-print",
      command: handlePrint,
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex gap-5 flex-wrap">
          <div className="flex  items-center gap-2">
            <label className="text-sm">{t("StartDate")}</label>
            <input
              required
              type="date"
              name="start_date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              id="dobDate"
              autoComplete="off"
              className="p-[0.33rem] border rounded-md"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="text-sm">{t("EndDate")}</label>
            <input
              required
              type="date"
              name="end_date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              id="dobDate"
              autoComplete="off"
              className="p-[0.33rem] border rounded-md"
            />
          </div>

          <div className="">
            <SharedButton
              label={t("Submit")}
              className="bg-gradient"
              onClick={handleSubmit}
              disabled={!startDate || !endDate}
            />
          </div>
        </div>

        <div className="">
          {/* <SharedButton
            label={`${t("Print")} `}
            icon="pi pi-print"
            onClick={handlePrint}
            className="bg-gradient"
          /> */}
          <SharedButton
          label={`${t("Export")}`}
          icon="pi pi-download"
          iconPos="right"
          onClick={(e) => menuRef.current.toggle(e)}
          className="bg-gradient"
        />
        <Menu
          pt={{
            root: { className: "!py-0 !bg-none" },
            content: { className: "!p-0 !bg-none !text-gray-500" },
            action: { className: "!py-1.5 !px-2 !bg-none !text-gray-500" },
            menuitem: { className: "!bg-none" },
            menu: { className: "!bg-gray-100" },
            icon:{ className: "!text-gray-500"}
          }}
          model={items}
          popup
          ref={menuRef}
        />
        </div>
      </div>

      <div className="this_div" ref={balanceSheetRef}>
        {incomeStatement ? (
          <IncomeCard incomeStatement={incomeStatement} />
        ) : null}
      </div>

      <iframe ref={iframeRef} style={{ display: "none" }}></iframe>

      <Loader />
    </div>
  );
}
