import React, { useState } from "react";
import { formatTimestamp } from "../../components/Shared/SharedMethods";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import {
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersList } from "../../store/slices/CustomerSlice";
import CustomPagination from "../../components/Shared/CustomPagination";
import { useTranslation } from "react-i18next";
import CreateCustomer from "./CreateCustomer";
import { deleteCustomer } from "../../store/slices/CustomerSlice";

export default function CustomerTable({ data }) {
  const datebody = (rowData) => {
    return formatTimestamp(rowData.CreatedAt);
  };
  const { isLoading } = useSelector((state) => state.customers);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();
  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getCustomersList({
        page: newPage,
        per_page: newRowsPerPage,
      })
    );
  };
  const templateBody = (rowData) => (
    <div className="flex gap-1">
      <SharedTooltip content="view" id="my-tooltip-1" />
      <SharedTooltip id="my-tooltip-2" content="edit" />
      <SharedTooltip id="my-tooltip-3" content="delete" />
      {/* <MdOutlineRemoveRedEye
        size={25}
        data-tooltip-id="my-tooltip-1"
        className="!p-1 !text-black hover:cursor-pointer"
      /> */}
      <MdOutlineEdit
        size={25}
        data-tooltip-id="my-tooltip-2"
        className="!p-1 !text-black hover:cursor-pointer"
        onClick={() => {
          setEditData(rowData);
          setCreateDialogVisible(true);
        }}
      />
      <MdOutlineDelete
        size={25}
        data-tooltip-id="my-tooltip-3"
        onClick={() => {
          dispatch(deleteCustomer({ id: rowData.id })).then((res) => {
            dispatch(getCustomersList({ page: 1, per_page: 10 }));
          });
        }}
        className="!p-1 !text-black hover:cursor-pointer"
      />
    </div>
  );
  const { t } = useTranslation();

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };

  return (
    <>
      <DataTable value={data?.data} tableStyle={{ minWidth: "50rem" }}>
        <Column
          body={sequenceBodyTemplate}
          header={t("no")}
          style={{ width: "3rem" }}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          field="name"
          header={t("name")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          field="document_id"
          header={t("document_id")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="primary_contact_number"
          header={t("primaryContactNumber")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          field="primary_email_id"
          header={t("primaryEmailId")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          field="status"
          header={t("status")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        {/* <Column
          field="CreatedAt"
          header={t("createdAt")}
          body={datebody}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        /> */}
        <Column
          body={templateBody}
          header={t("actions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
      </DataTable>
      <CreateCustomer
        setCreateDialogVisible={setCreateDialogVisible}
        createDialogVisible={createDialogVisible}
        editData={editData}
      />
      <Loader showLoader={isLoading} />
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
