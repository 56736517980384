import React, { useEffect, useRef, useState } from "react";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getTaxReturnReportList,
  getTrialBalanceList,
} from "../../store/slices/ReportSlice";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { getOneMonthAgo } from "../CashInflow/CashInflow";
import { Card } from "primereact/card";
import VatReturnReportTable from "./VatReturnReportTable";
import * as XLSX from "xlsx";
import { Menu } from "primereact/menu";

export default function VatReturnReport() {
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [startDate, setStartDate] = useState(getOneMonthAgo(endDate));
  const { isLoading, taxReturnReportList } = useSelector(
    (state) => state.reports
  );
  const dispatch = useDispatch();
  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);
  const menuRef = useRef(null);
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (startDate && endDate) {
      const payload = {
        start_date: startDate,
        end_date: endDate,
        page: 1,
        per_page: 20,
      };
      dispatch(getTaxReturnReportList(payload));
    }
  };

  useEffect(() => {
    const payload = {
      start_date: startDate,
      end_date: endDate,
      page: 1,
      per_page: 20,
    };
    dispatch(getTaxReturnReportList(payload));
  }, []);

  const handlePrint = () => {
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write("<html><head><title>Balance Sheet</title>");
    doc.write(
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">'
    );

    doc.write("<style>");
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0; /* Removes the default margins */
        }
        body {
          margin: 1cm; /* Adjust the margins to your needs */
        }
        /* Hide browser print headers and footers */
        header, footer {
          display: none;
        }
      }
    `);
    doc.write("</style>");

    doc.write("</head><body>");
    doc.write(printContents);
    doc.write("</body></html>");
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  const extractData = (element) => {
    const data = [];

    // Extract taxpayer information
    const taxpayerInfo = element.querySelector(
      ".bg-white.rounded.px-4.py-2.mb-4.border"
    );
    if (taxpayerInfo) {
      data.push(["Taxpayer Information"]);
      taxpayerInfo.querySelectorAll(".flex.justify-between").forEach((item) => {
        const label = item.querySelector(".font-semibold").textContent.trim();
        const value = item.querySelector("span:last-child").textContent.trim();
        data.push([label, value]);
      });
      data.push([]);
    }

    // Extract VAT return table data
    const table = element.querySelector("table");
    if (table) {
      data.push(["VAT Return Details"]);
      table.querySelectorAll("tr").forEach((row) => {
        const rowData = Array.from(row.querySelectorAll("th, td")).map((cell) =>
          cell.textContent.trim()
        );
        data.push(rowData);
      });
      data.push([]);
    }

    // Extract summary information
    const summary = element.querySelector(
      ".bg-white.rounded.px-4.py-2.mt-4.border"
    );
    if (summary) {
      data.push(["Summary"]);
      summary.querySelectorAll(".flex.justify-between").forEach((item) => {
        const label = item.querySelector(".font-semibold").textContent.trim();
        const value = item.querySelector("span:last-child").textContent.trim();
        data.push([label, value]);
      });
    }

    return data;
  };

  const handleExcelExport = () => {
    const wb = XLSX.utils.book_new();
    const data = extractData(balanceSheetRef.current);
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Set column widths
    const colWidths = [{ wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
    ws["!cols"] = colWidths;

    XLSX.utils.book_append_sheet(wb, ws, "VAT Return Report");
    XLSX.writeFile(wb, "VAT Return Report.xlsx");
  };

  const items = [
    {
      label: t("EXCEL"),
      icon: "pi pi-file-excel",
      command: handleExcelExport,
    },
    {
      label: t("PDF"),
      icon: "pi pi-print",
      command: handlePrint,
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex gap-5 flex-wrap">
          <div className="flex  items-center gap-2">
            <label className="text-sm">{t("StartDate")}</label>
            <input
              required
              type="date"
              name="start_date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              id="dobDate"
              autoComplete="off"
              className="p-[0.33rem] border rounded-md"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="text-sm">{t("EndDate")}</label>
            <input
              required
              type="date"
              name="end_date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              id="dobDate"
              autoComplete="off"
              className="p-[0.33rem] border rounded-md"
            />
          </div>
          <div className="">
            <SharedButton
              label={t("Submit")}
              className="bg-gradient"
              onClick={handleSubmit}
              disabled={!startDate || !endDate}
            />
          </div>
        </div>

        <div className="">
          <SharedButton
            label={`${t("Export")}`}
            icon="pi pi-download"
            iconPos="right"
            onClick={(e) => menuRef.current.toggle(e)}
            className="bg-gradient"
          />
          <Menu
            pt={{
              root: { className: "!py-0 !bg-none" },
              content: { className: "!p-0 !bg-none !text-gray-500" },
              action: { className: "!py-1.5 !px-2 !bg-none !text-gray-500" },
              menuitem: { className: "!bg-none" },
              menu: { className: "!bg-gray-100" },
              icon: { className: "!text-gray-500" },
            }}
            model={items}
            popup
            ref={menuRef}
          />
          {/* <SharedButton
            label={`${t("Print")} `}
            icon="pi pi-print"
            onClick={handlePrint}
            className="bg-gradient"
          /> */}
        </div>
      </div>

      <div className="this_div" ref={balanceSheetRef}>
        <div className="pb-8">
          <div className="title !my-8">
            <div className="flex justify-center">
              <div className="mx-10 !mt-1">
                <div className="font-semibold text-black/70 text-xl pb-4">
                  {`${t("VAT Return Report")}`}
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
              }}
            >
              <div className="">
                {/* <TrialBalanceTable data={trialBalanceList} /> */}
                <VatReturnReportTable data={taxReturnReportList} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <iframe ref={iframeRef} style={{ display: "none" }}></iframe>

      {/* <Loader showLoader={isLoading} /> */}
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getBalaceSheet } from "../../store/slices/ReportSlice";
// import { useTranslation } from "react-i18next";
// import PatientsVatReturn from "./PatientsVatReturn";
// import CompaniesVatReturn from "./CompaniesVatReturn";

// export default function VatReturnReport() {
//   const { t } = useTranslation();
//   const [activeTab, setActiveTab] = useState("patientsVatReturn");
//   const dispatch = useDispatch();
//   const { loading, balanceSheet } = useSelector((state) => state.reports);
//   const tabList = [
//     {
//       label: t("Patients VAT Return"),
//       comapre: "patientsVatReturn",
//       command: () => setActiveTab("patientsVatReturn"),
//     },
//     {
//       label: t("Companies VAT Return"),
//       comapre: "companiesVatReturn",
//       command: () => setActiveTab("companiesVatReturn"),
//     },
//   ];

//   return (
//     <div>
//       <div>
//         {" "}
//         <div className=" col-span-5 p-2 rounded h-[75vh] overflow-auto">
//           <ul className="grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner">
//             {tabList.map((tab) => (
//               <li>
//                 <a
//                   className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
//                     activeTab === tab?.comapre
//                       ? " bg-gradient rounded shadow text-white "
//                       : ""
//                   }`}
//                   onClick={() => tab?.command()}
//                 >
//                   {tab.label}
//                 </a>
//               </li>
//             ))}
//           </ul>
//           <div>
//             {activeTab === "patientsVatReturn" ? (
//                 <PatientsVatReturn />
//             ) : activeTab === "companiesVatReturn" ? (
//                 <CompaniesVatReturn />
//             ) : null}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
