import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useField } from "formik";

const CustomSearchInput = ({
  options,
  label,
  name,
  placeholder,
  className,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  // const handleChange = (selectedOption) => {
  //   const selectedValue = selectedOption.value;
  //   helpers.setValue(selectedValue);
  // };
  const handleChange = (selectedOption) => {
    helpers.setValue(selectedOption.value);
    if (onChange) {
      onChange(selectedOption);
    }
  };

  const getValue = () => {
    if (options) {
      return options.find(option => option.value === field.value) || null;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="flex flex-col gap-1 w-full">
        <label htmlFor={name} className="text-base font-medium">
          {label}
        </label>
        <Select
          classNames={{
            control: (state) =>
              state.isFocused ? "!border-primary" : "!border-gray-300",
          }}
          className={`!cursor-text !p-0 w-full border-gray-300 rounded-md hover:!border-primary !outline-none shadow-none ${className}`}
          classNamePrefix="select"
          options={options}
          name={name}
          placeholder={placeholder}
          value={getValue()}
          // value={field.label}
          onChange={handleChange}
          onBlur={() => helpers.setTouched(true)}
          {...props}
        />
        {meta.error && meta.touched && (
          <p className="text-sm text-red-500">{meta.error}</p>
        )}
      </div>
    </>
  );
};

export default CustomSearchInput;

// import React, { useState } from 'react';
// import Select from 'react-select';

// const Checkbox = ({ children, ...props }) => (
//   <label style={{ marginRight: '1em' }}>
//     <input type="checkbox" {...props} />
//     {children}
//   </label>
// );

// const CustomSearchInput = ({ options, defaultValue, name }) => {
//   const [isClearable, setIsClearable] = useState(true);
//   const [isSearchable, setIsSearchable] = useState(true);
//   const [isDisabled, setIsDisabled] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isRtl, setIsRtl] = useState(false);

//   return (
//     <>
//       <Select
//         className="basic-single"
//         classNamePrefix="select"
//         defaultValue={defaultValue}
//         isDisabled={isDisabled}
//         isLoading={isLoading}
//         isClearable={isClearable}
//         isRtl={isRtl}
//         isSearchable={isSearchable}
//         name={name}
//         options={options}
//       />

//       {/* <div
//         style={{
//           color: 'hsl(0, 0%, 40%)',
//           display: 'inline-block',
//           fontSize: 12,
//           fontStyle: 'italic',
//           marginTop: '1em',
//         }}
//       >
//         <Checkbox
//           checked={isClearable}
//           onChange={() => setIsClearable((state) => !state)}
//         >
//           Clearable
//         </Checkbox>
//         <Checkbox
//           checked={isSearchable}
//           onChange={() => setIsSearchable((state) => !state)}
//         >
//           Searchable
//         </Checkbox>
//         <Checkbox
//           checked={isDisabled}
//           onChange={() => setIsDisabled((state) => !state)}
//         >
//           Disabled
//         </Checkbox>
//         <Checkbox
//           checked={isLoading}
//           onChange={() => setIsLoading((state) => !state)}
//         >
//           Loading
//         </Checkbox>
//         <Checkbox checked={isRtl} onChange={() => setIsRtl((state) => !state)}>
//           RTL
//         </Checkbox>
//       </div> */}
//     </>
//   );
// };

// export default CustomSearchInput;
